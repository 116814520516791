import { ArrowBack, FilterList } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputAdornmentProps,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocationFilters } from '../../../api/tronc';
import TitledDrawer from '../../../components/TitledDrawer';
import {
  comboStyle,
  desktopButtonStyle,
  desktopSecondaryButtonStyle,
  mobileButtonStyle,
  mobileSecondaryButtonStyle,
} from '../../../constants';
import { TInputDateToString } from '../../../helpers/dateUtils';
import { defer } from '../../../helpers/helpers';
import { TroncReportFilters } from '../../../models/tronc';
import { UIContext } from '../../../context/UIContext';
import { DEFAULT_LOCATION_VALUE_KEY, TroncFiltersContext } from '../../../context/TroncFiltersContext';

const subHeaderStyle: CSSProperties = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
};

const TroncReportFiltersPanel = () => {
  const {
    filters,
    unsavedFilters,
    setFilters,
    applyFilters,
    filtersChanged,
    resetFilters,
    closeFilters,
    validationErrors,
    dateRangeConfig,
  } = useContext(TroncFiltersContext);

  const theme = useTheme();
  const { isMobile, isTablet } = useContext(UIContext);
  const buttonStyle = (isMobile || isTablet) ? mobileButtonStyle : desktopButtonStyle;
  const secondaryButtonStyle = isMobile ? mobileSecondaryButtonStyle : desktopSecondaryButtonStyle;

  const { t } = useTranslation();

  const bottomToolbar = (
    <Box>
      <Box
        sx={{
          display: 'flex', 
          flexDirection: (isMobile || isTablet) ? 'column' : 'row-reverse',
          marginLeft: 'auto',
          padding: '16px'
        }}
      >
        <Button
          variant="contained"
          sx={[buttonStyle]}
          onClick={() => {
            if (applyFilters()) defer(() => closeFilters(true));
          }}
          data-qa-id="filterApplyButton"
        >
          {t('components.filters.apply')}
        </Button>
        {filtersChanged && (
          <Button
            variant="text"
            sx={{ ...secondaryButtonStyle }}
            onClick={resetFilters}
            data-qa-id="filterResetButton"
          >
            {t('components.filters.reset')}
          </Button>
        )}
      </Box>
    </Box>
  );

  const errorsFor = useCallback(
    (key: string) =>
      validationErrors
        .filter((it) => it.field === key)
        .map((it) => it.message)
        .join(', '),
    [validationErrors]
  );
  const hasErrors = useCallback((key: string) => errorsFor(key).length > 0, [errorsFor]);
  const onChangePeriod = useCallback(
    (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setFilters(
        (prevFilters: TroncReportFilters) =>
          ({
            ...prevFilters,
            period: ev.target.value,
          } as TroncReportFilters)
      ),
    [setFilters]
  );
  const onChangePeriodFrom = useCallback(
    (date: Date | undefined | null) =>
      setFilters(
        (prevFilters: TroncReportFilters) =>
          ({
            ...prevFilters,
            periodFrom: TInputDateToString(date),
          } as TroncReportFilters)
      ),
    [setFilters]
  );
  const onChangePeriodTo = useCallback(
    (date: Date | undefined | null) =>
      setFilters(
        (prevFilters: TroncReportFilters) =>
          ({
            ...prevFilters,
            periodTo: TInputDateToString(date),
          } as TroncReportFilters)
      ),
    [setFilters]
  );
  const [openPeriodFrom, setOpenPeriodFrom] = useState(false);
  const [openPeriodTo, setOpenPeriodTo] = useState(false);

  const pickerFrom = useMemo(
    () => (unsavedFilters.periodFrom ? new Date(unsavedFilters.periodFrom) : new Date()),
    [unsavedFilters.periodFrom]
  );
  const pickerTo = useMemo(
    () => (unsavedFilters.periodTo ? new Date(unsavedFilters.periodTo) : new Date()),
    [unsavedFilters.periodTo]
  );
  const customStartPicker = useCallback(
    (p: InputAdornmentProps) => (
      <InputAdornment {...p} position="end" onClick={() => setOpenPeriodFrom(true)}>
        <IconButton aria-label="Period from (show calendar)" sx={{ left: '12px' }}>
          {hasErrors('periodFrom') ? <ErrorOutlineIcon color="error" /> : <EventOutlinedIcon />}
        </IconButton>
      </InputAdornment>
    ),
    [setOpenPeriodFrom, hasErrors]
  );
  const customEndPicker = useCallback(
    (p: InputAdornmentProps) => (
      <InputAdornment {...p} position="end" onClick={() => setOpenPeriodTo(true)}>
        <IconButton aria-label="Period to (show calendar)" sx={{ left: '12px' }}>
          {hasErrors('periodTo') ? <ErrorOutlineIcon color="error" /> : <EventOutlinedIcon />}
        </IconButton>
      </InputAdornment>
    ),
    [setOpenPeriodTo, hasErrors]
  );

  const fireResize = useCallback(() => {
    if (typeof Event === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      // for IE and other old browsers
      // causes deprecation warning on modern browsers
      const evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    }
  }, []);

  const { data: filtersData, refetch: fetchFilters } = useLocationFilters(
    `troncFilters`,
    {},
  );

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters, unsavedFilters.companyGuid]);

  return (
    <TitledDrawer
      title={t('components.filters.filters')}
      onClose={() => closeFilters()}
      icon={
        !isMobile && !isTablet ?
        <FilterList
          sx={{
            color: 'text.secondary',
          }}
        /> :
        <ArrowBack
          sx={{
            color: 'text.secondary',
          }}
        />
      }
      bottomToolbar={bottomToolbar}
      bottomToolbarMarginTop='16px'
      iconRight={(isMobile || isTablet) ?
        <FilterList
          sx={{
            color: 'text.secondary',
          }}
        /> : undefined
      }
    >
      <Grid
        item
        sx={{
          paddingLeft: '4px',
          paddingRight: '4px'
        }}
      >
        <Accordion
          elevation={0}
          defaultExpanded
          disableGutters
          square
          sx={{
            padding: 0,
            border: 0,
            margin: 0,
          }}
          onClick={() => setTimeout(fireResize, 250)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="timeFilters-content"
            sx={{ padding: 0, border: 0, margin: 0 }}
            id="timeFilters-header"
            data-qa-id="timeFilterHeader"
          >
            <Typography sx={subHeaderStyle} variant="h3">
              {t('components.filters.time')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, border: 0, margin: 0 }}>
            <TextField
              size="small"
              sx={comboStyle}
              select
              label={t('components.datePicker.timePeriod')}
              value={unsavedFilters.period}
              id="period"
              onChange={onChangePeriod}
              inputProps={{'data-qa-id':"filterPeriodOption"}}
            >
              <MenuItem value="today">{t('components.datePicker.today')}</MenuItem>
              <MenuItem value="yesterday">{t('components.datePicker.yesterday')}</MenuItem>
              <MenuItem value="thisWeek">{t('components.datePicker.thisWeek')}</MenuItem>
              <MenuItem value="lastWeek">{t('components.datePicker.lastWeek')}</MenuItem>
              <MenuItem value="thisMonth">{t('components.datePicker.thisMonth')}</MenuItem>
              <MenuItem value="lastMonth">{t('components.datePicker.lastMonth')}</MenuItem>
              <MenuItem value="custom">{t('components.datePicker.custom')}</MenuItem>
            </TextField>
            <DateTimePicker
              label={t('components.datePicker.startDate')}
              value={pickerFrom}
              onChange={onChangePeriodFrom}
              open={openPeriodFrom}
              onOpen={() => setOpenPeriodFrom(true)}
              onClose={() => setOpenPeriodFrom(false)}
              slots={{
                inputAdornment: customStartPicker,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  error: hasErrors('periodFrom'),
                  helperText:
                    errorsFor('periodFrom') ||
                    `${t('components.datePicker.aFullDayIsFrom')} ${dateRangeConfig.daysStartAt
                      .toString(10)
                      .padStart(2, '0')}:00 ${t(
                      'components.datePicker.to'
                    )} ${dateRangeConfig.daysStartAt.toString(10).padStart(2, '0')}:00`,
                  style: comboStyle,
                  inputProps: {
                    'data-qa-id': "filterStartDateTime"
                  }
                },
              }}
              format={t('components.datePicker.dateTimeFormat')}
            />
            <DateTimePicker
              label={t('components.datePicker.endDate')}
              value={pickerTo}
              onChange={onChangePeriodTo}
              open={openPeriodTo}
              onOpen={() => setOpenPeriodTo(true)}
              onClose={() => setOpenPeriodTo(false)}
              format={t('components.datePicker.dateTimeFormat')}
              slots={{
                inputAdornment: customEndPicker,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  error: hasErrors('periodTo'),
                  style: comboStyle,
                  helperText:
                    errorsFor('periodTo') ||
                    `${t('components.datePicker.aFullDayIsFrom')} ${dateRangeConfig.daysStartAt
                      .toString(10)
                      .padStart(2, '0')}:00 ${t(
                      'components.datePicker.to'
                    )} ${dateRangeConfig.daysStartAt.toString(10).padStart(2, '0')}:00`,
                  inputProps: {
                    'data-qa-id': "filterStartDateTime"
                  }
                },
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          defaultExpanded
          square
          disableGutters
          sx={{ padding: 0, border: 0, margin: 0 }}
          onClick={() => setTimeout(fireResize, 250)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ padding: 0, border: 0, margin: 0 }}
            aria-controls="areaFilters-content"
            id="areaFilters-header"
            data-qa-id="areaFilterHeader"
          >
            <Typography sx={subHeaderStyle} variant="h3">
              {t('components.filters.location')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, border: 0, margin: 0, marginBottom: 1}}>
            <TextField
              size="small"
              select
              value={unsavedFilters.location ? JSON.stringify(unsavedFilters.location) : DEFAULT_LOCATION_VALUE_KEY}
              label={t('components.filters.location')}
              id="location-label"
              sx={comboStyle}
              error={hasErrors('location')}
              helperText={errorsFor('location')}
              onChange={(ev) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  location: ev.target.value === DEFAULT_LOCATION_VALUE_KEY ? undefined : JSON.parse(ev.target.value),
                }))
              }
              inputProps={{'data-qa-id':"filterLocationOption"}}
            >
              <MenuItem value={DEFAULT_LOCATION_VALUE_KEY}>{t('components.filters.selectLocation')}</MenuItem>
              {filtersData &&
                filtersData.locations &&
                filtersData.locations.map((location) => (
                  <MenuItem value={JSON.stringify(location)} key={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
            </TextField>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </TitledDrawer>
  );
};

export default TroncReportFiltersPanel;
