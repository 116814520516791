import { TableBody } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";
import IconMessage, { IconMessageIcon } from "../../../components/IconMessage";
import { denseTbodyStyle, tbodyStyle } from "../../transactions/constants";

export interface FailedToLoadProps {
    dense: boolean;
    onClickRetry: () => void;
}

const FailedToLoadTronc = (props: FailedToLoadProps) => {
    const { onClickRetry, dense } = props;
    const { t } = useTranslation();
    return <TableBody
        style={dense ? denseTbodyStyle : tbodyStyle}>
        <TableRow style={dense ? denseTbodyStyle : tbodyStyle}>
            <TableCell colSpan={20} padding="none"
                style={{
                    height: dense ? 'calc(100vh - 492px)' : 'calc(100vh - 512px)',
                    borderBottom: "none",
                    width: "100vw"
                }}>
                <IconMessage
                    title={t("components.iconMessage.failedToLoad.title")}
                    icon={IconMessageIcon.ERROR}
                    subtitle={t("components.iconMessage.failedToLoad.subtitle")}
                    buttonText={t("components.iconMessage.failedToLoad.buttonText")}
                    onClickButton={onClickRetry} />
            </TableCell>
        </TableRow>
    </TableBody>
};

export default FailedToLoadTronc;
