import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import AdminGuard from './guards/AdminGuard';
import Debug from './screens/debug/Debug';
import NotFound from './screens/error/NotFound';
import Home from './screens/home/Home';
import NoAccess from "./screens/no-access/NoAccess";
import Settings from "./screens/settings/Settings";
import Transactions from './screens/transactions/Transactions';
import Tronc from './screens/tronc/Tronc';

const DEFAULT_PATH = "/transactions";

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Navigate to={DEFAULT_PATH} replace/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/debug" element={<Debug/>}/>
        <Route path="/transactions"
               element={<Transactions/>}>
            <Route path=":transactionId" element={null}/>
        </Route>
        <Route path="/admin/transactions"
               element={<AdminGuard><Transactions adminView/></AdminGuard>}/>
        <Route path="/settings" element={<Settings/>}/>
            <Route path="/no-access" element={<NoAccess/>}/>
        <Route path="*" element={<NotFound/>}/>
        <Route path="/tronc" element={<Tronc/>}/>
    </Routes>
);

export default AppRoutes;
