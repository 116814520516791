import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from "react";
import {useContext} from "react";
import {UserContext} from "../../../context/UserContext";
import {EN_GB, GBP} from "../../../helpers/helpers";

function createData(
  name: string,
  quantity: number,
  amount: number,
) {
  return { name, quantity, amount };
}

const rows = [
  createData('Eclair', 262, 160.56),
  createData('Frozen yoghurt', 159, 63.6),
  createData('Ice cream sandwich', 237, 900.11),
];

function formatValue(inputNumber: number, isCurrency: boolean, locale = EN_GB) {
  if (isCurrency) {
    return Intl.NumberFormat(locale, {style: 'currency', currency: GBP}).format(inputNumber);
  }

  return Intl.NumberFormat(locale).format(inputNumber);
}

const BasicTable = () => {
  const {locale} = useContext(UserContext);
  return <TableContainer component={Paper}>
    <Table sx={{minWidth: 300}} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell align="right">Quantity</TableCell>
          <TableCell align="right">Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
            <TableRow
                key={row.name}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{formatValue(row.quantity, false, locale)}</TableCell>
              <TableCell align="right">{formatValue(row.amount, true, locale)}</TableCell>
            </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
};

export default BasicTable;
