import {setupWorker} from 'msw';
import TransactionsHandler from './handlers/transactionHandlers'
import TroncHandler from './handlers/troncHandlers'
// This configures a Service Worker with the given request handlers.
// ultimately these get swapped out with real APIs

const worker = setupWorker(
    // ...TransactionsHandler,
    // ...TroncHandler
);


export default worker;
