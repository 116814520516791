import * as React from 'react';
import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { ArrowBack, ReceiptLong, CloseOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as Sentry from '@sentry/react';
import { useAuth } from 'oidc-react';
import {
  flagTransaction,
  unflagTransaction,
  useTransactionDetails,
} from '../../../api/transactions';
import { ContactForm } from '../../../components/ContactDialog';
import IconMessage, { IconMessageIcon } from '../../../components/IconMessage';
import TitledDrawer from '../../../components/TitledDrawer';
import { SettingsContext } from '../../../context/SettingsContext';
import { UIContext } from '../../../context/UIContext';
import { UserContext } from '../../../context/UserContext';
import { formatDate } from '../../../helpers/dateUtils';
import { formatValue, hasModule } from '../../../helpers/helpers';
import {
  TaxRate,
  TransactionDetails,
  TransactionFlag,
  TransactionItem,
} from '../../../models/transactions';
import TransactionFeedbackButton from './TransactionFeedbackButton';
import WithScrollbars from './WithScrollbars';

export interface TransactionReportDetailsPanelProps {
  transactionId?: string;
  onClose: () => void;
  adminView?: boolean;
  startOnFeedback?: boolean;
  companyId?: string;
  refetchTable: () => void;
}

const PlainTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme?.palette?.common?.black,
    color: theme?.palette?.common?.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const PlainTableRow = styled(TableRow)(() => ({
  'td, th': {
    border: 0,
    padding: '4px',
  },
}));

// Hacky way to use flatmap on POS
function legacyFlatMap<T, U>(array: T[], callback: (item: T) => U[]): U[] {
  return array.reduce((accumulator, item) => accumulator.concat(callback(item)), [] as U[]);
}

const TransactionReportDetailsPanel = (props: TransactionReportDetailsPanelProps) => {
  const { isMobile, isTablet } = useContext(UIContext);
  const { transactionId, onClose, adminView, startOnFeedback, companyId, refetchTable } = props;
  const { t } = useTranslation();
  const [feedbackSent, setFeedbackSent] = useState<TransactionFlag | undefined>();
  const { locale, userModules } = useContext(UserContext);
  const hasModuleInvoiceNumber = hasModule(userModules, "ModuleInvoiceNumbers");
  const { colorMode } = useContext(UIContext);
  const sepColor = colorMode === 'light' ? '1px solid #e1e1e1' : '1px solid #FFFFFF3B';

  const {
    data: detailsData,
    refetch: fetchDetails,
    isFetching: detailsFetching,
    isError: detailsError,
  } = useTransactionDetails<TransactionDetails>(
    transactionId || '',
    `transactionDetails${adminView ? 'Admin' : ''}_${transactionId}`,
    { enabled: !!transactionId && transactionId !== 'ERR' },
    companyId
  );

  useEffect(() => {
    if (transactionId) {
      fetchDetails().then();
    }
  }, [fetchDetails, transactionId]);

  useEffect(() => {
    if (detailsData?.activeFlagReport) {
      setFeedbackSent(detailsData?.activeFlagReport);
    }
  }, [detailsData, setFeedbackSent]);

  function getTwoColumnTable(
    rows:
      | {
          label: string;
          value?: string | number | boolean;
          sx?: CSSProperties;
        }[]
      | undefined,
    sx: CSSProperties = {},
    header = '',
    headerSx: CSSProperties = {}
  ) {
    if (!rows) {
      return null;
    }
    const filtered = rows.filter(
      (row) => row.value !== undefined && row.value.toString().trim() !== ''
    );
    return filtered.length > 0 ? (
      <Table
        aria-label="simple table"
        sx={{ width: '100%', border: 0, borderCollapse: 'initial', ...sx }}
      >
        <TableBody sx={{ ...sx }}>
          {header !== '' && (
            <PlainTableRow key={header} sx={{ ...headerSx }}>
              <PlainTableCell component="td" scope="row" sx={{ fontWeight: 600, ...headerSx }}>
                {header}
              </PlainTableCell>
            </PlainTableRow>
          )}
          {filtered.map((row) => (
            <PlainTableRow key={row.label} sx={{ ...row.sx }}>
              <PlainTableCell component="td" scope="row" sx={{ ...row.sx }}>
                {row.label}
              </PlainTableCell>
              <PlainTableCell align="right" sx={{ ...row.sx }}>
                {row.value}
              </PlainTableCell>
            </PlainTableRow>
          ))}
        </TableBody>
      </Table>
    ) : null;
  }

  function getTransactionItemsTable(
    rows: TransactionItem[],
    currency?: string,
    sx: CSSProperties = {}
  ) {
    return rows.length > 0 ? (
      <Table
        aria-label="simple table"
        sx={{ width: '100%', border: 0, borderCollapse: 'initial', ...sx }}
      >
        <TableBody sx={{ ...sx }}>
          <PlainTableRow key="headitems">
            <PlainTableCell scope="row" sx={{ fontWeight: 600 }}>
              {t('screens.transactions.fields.product')}
            </PlainTableCell>
            <PlainTableCell scope="row" align="right" sx={{ fontWeight: 600 }}>
              {t('screens.transactions.fields.price')}
            </PlainTableCell>
            <PlainTableCell scope="row" align="right" sx={{ fontWeight: 600 }}>
              {t('screens.transactions.fields.qty')}
            </PlainTableCell>
            <PlainTableCell scope="row" align="right" sx={{ fontWeight: 600 }}>
              {t('screens.transactions.fields.total')}
            </PlainTableCell>
          </PlainTableRow>
          {rows.map((row) => (
            <>
              <PlainTableRow key={`${row.product}-${row.price}-${row.quantity}-${row.totalAmount}`}>
                <PlainTableCell component="td" scope="row" style={{wordBreak: 'break-all'}}>
                  {row.product}
                </PlainTableCell>
                <PlainTableCell component="td" scope="row" align="right">
                  {row.productType === 'Standard'
                    ? formatValue(row.price, true, currency, locale)
                    : ''}
                </PlainTableCell>
                <PlainTableCell component="td" scope="row" align="right">
                  {row.quantity}
                </PlainTableCell>
                <PlainTableCell component="td" scope="row" align="right">
                  {formatValue(row.totalAmount, true, currency, locale)}
                </PlainTableCell>
              </PlainTableRow>
              {row.discountAmount !== 0 && (
                <PlainTableRow
                  key={`discount ${row.product} ${row.price} ${row.quantity} ${row.totalAmount}`}
                >
                  <PlainTableCell
                    component="td"
                    scope="row"
                    colSpan={3}
                    style={{ fontSize: '13px', color: 'grey', paddingLeft: '16px' }}
                  >
                    {t('screens.transactions.fields.discountAmount')}:&nbsp;{row.discountReason}
                  </PlainTableCell>
                  <PlainTableCell
                    component="td"
                    scope="row"
                    align="right"
                    style={{ fontSize: '13px', color: 'grey' }}
                  >
                    {formatValue(-row.discountAmount, true, currency, locale)}
                  </PlainTableCell>
                </PlainTableRow>
              )}
              {row.productType !== 'Standard' && (
                <PlainTableRow
                  key={`measured ${row.measuredQuantity} ${row.symbol} ${currency} ${row.price}`}
                >
                  <PlainTableCell
                    component="td"
                    scope="row"
                    colSpan={3}
                    style={{ fontSize: '13px', color: 'grey', paddingLeft: '16px' }}
                  >
                    {row.measuredQuantity} {row.symbol} @{' '}
                    {formatValue(row.price, true, currency, locale, 4)} / {row.symbol}
                  </PlainTableCell>
                </PlainTableRow>
              )}
              {(row.barcode || row.sku) && (
                <PlainTableRow>
                  {row.barcode && (
                    <PlainTableCell align='left' colSpan={2}>
                      <span style={{ fontWeight: 600 }}>
                        {t('screens.transactions.fields.barcode')}
                      </span>&nbsp;{row.barcode}
                    </PlainTableCell>
                  )}
                  {row.sku && (
                    <PlainTableCell align='right' colSpan={row.barcode ? 2 : 4}>
                      <span style={{ fontWeight: 600 }}>
                        {t('screens.transactions.fields.sku')}
                      </span>&nbsp;{row.sku}
                    </PlainTableCell>
                  )}
                </PlainTableRow>
              )}
              {row.notes && (
                <PlainTableRow key={`note_head_${row.product}_${row.notes}`}>
                  <PlainTableCell sx={{ fontWeight: 600 }}>{t('screens.transactions.fields.notes')}</PlainTableCell>
                </PlainTableRow>
              )}
              {row.notes && (
                <PlainTableRow key={`note_${row.product}_${row.notes}`}>
                  <PlainTableCell colSpan={4}>
                      {row.notes}
                    </PlainTableCell>
                </PlainTableRow>
              )}
              <PlainTableCell colSpan={4} style={{ borderBottom: sepColor, padding: 0 }}/>
            </>
          ))}
        </TableBody>
      </Table>
    ) : null;
  }

  function getTaxRateTable(rows: TaxRate[], currency?: string, sx: CSSProperties = {}) {
    return rows.length > 0 ? (
      <Table
        aria-label="simple table"
        sx={{ width: '100%', border: 0, borderCollapse: 'initial', ...sx }}
      >
        <TableBody sx={{ ...sx }}>
          <PlainTableRow key="headitems">
            <PlainTableCell scope="row" sx={{ fontWeight: 600 }}>
              {t('screens.transactions.fields.taxRate')}
            </PlainTableCell>
            <PlainTableCell scope="row" align="right" sx={{ fontWeight: 600 }}>
              {t('screens.transactions.fields.percentage')}
            </PlainTableCell>
            <PlainTableCell scope="row" align="right" sx={{ fontWeight: 600 }}>
              {t('screens.transactions.fields.tax')}
            </PlainTableCell>
          </PlainTableRow>
          {rows.map((row) => (
            <PlainTableRow key={`${row.name}-${row.percentage}-${row.amount}`}>
              <PlainTableCell component="td" scope="row">
                {row.name}
              </PlainTableCell>
              <PlainTableCell component="td" scope="row" align="right">
                {formatValue(row.percentage, false, undefined, locale)} %
              </PlainTableCell>
              <PlainTableCell component="td" scope="row" align="right">
                {formatValue(row.amount, true, currency, locale)}
              </PlainTableCell>
            </PlainTableRow>
          ))}
        </TableBody>
      </Table>
    ) : null;
  }

  function getDiscounts() {
    return (detailsData?.topLevelDiscountsDetails || []).map((d) => ({
      label: `${t(`screens.transactions.fields.discounts.${d.type}`)} (${d.label})`,
      value: formatValue(-d.amount, true, detailsData?.currency, locale),
    }));
  }

  function getBody() {
    return (
      <TableContainer component={Box}>
        {detailsFetching && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              overflow: 'hidden',
              opacity: 0.6,
            }}
          >
            <CircularProgress
              aria-label={t('components.loader.loading')}
              aria-live="polite"
              aria-busy={detailsFetching}
              data-testid="loading-icon"
              sx={{ color: 'text.secondary' }}
            />
          </Box>
        )}
        {!detailsFetching &&
          getTwoColumnTable(
            [
              ...(adminView && companyId
                ? [
                    {
                      label: t('screens.transactions.fields.companyId'),
                      value: companyId,
                    },
                  ]
                : []),
              {
                label: t('screens.transactions.fields.time'),
                value:
                  detailsData?.date && detailsData.date !== 'TBD'
                    ? formatDate(detailsData.date, locale)
                    : undefined,
              },
              { label: t('screens.transactions.fields.staff'), value: detailsData?.staff },
              { label: t('screens.transactions.fields.location'), value: detailsData?.location },
              { label: t('screens.transactions.fields.device'), value: detailsData?.device },
              { label: t('screens.transactions.fields.customer'), value: detailsData?.customer },
              { label: t('screens.transactions.fields.barcode'), value: detailsData?.barcode },
              ],
            { borderBottom: sepColor, paddingBottom: '16px' }
          )}
          {hasModuleInvoiceNumber && !detailsFetching && detailsData?.invoiceNumbers &&
            getTwoColumnTable(
              detailsData?.invoiceNumbers?.map(invoice => ({
                label: '',
                value: invoice,
                sx: { paddingTop: '0px !important', paddingBottom: '0px !important'}
              })),
              { borderBottom: sepColor, paddingTop: '4px', paddingBottom: '16px' },
              t('screens.transactions.fields.invoiceNumbers'),
            )
          }
        {!detailsFetching &&
          (detailsData?.transactionItems ||
            detailsData?.miscProductItems ||
            detailsData?.customerCreditItems) &&
          getTransactionItemsTable(
            [
              ...(detailsData?.transactionItems || []),
              ...(detailsData?.miscProductItems || []).map((it) => ({
                product: it.name,
                price: it.price,
                quantity: 1,
                measuredQuantity: 0,
                symbol: '',
                productType: 'Standard' as const,
                totalAmount: it.price,
                discountReason: '',
                discountAmount: 0,
                barcode: '',
                sku: '',
                notes: '',
              })),
              ...(detailsData?.customerCreditItems || []).map((it) => ({
                product: t('screens.transactions.fields.customerCredit'),
                price: undefined as unknown as number,
                quantity: undefined as unknown as number /* quick hack to hide the field */,
                measuredQuantity: 0,
                symbol: '',
                productType: 'Standard' as const,
                totalAmount: it.amount,
                discountReason: '',
                discountAmount: 0,
                barcode: '',
                sku: '',
                notes: '',
              }))
            ],
            detailsData?.currency,
            {
              paddingTop: '16px',
            }
          )}
        {!detailsFetching &&
          getTwoColumnTable(
            [
              {
                label: t('screens.transactions.fields.subTotal'),
                value:
                  detailsData?.subTotal !== undefined &&
                  formatValue(detailsData?.subTotal, true, detailsData?.currency, locale),
              },
              ...(detailsData?.serviceCharge !== undefined && detailsData?.serviceCharge !== 0
                ? [
                    {
                      label: t('screens.transactions.fields.serviceCharge'),
                      value: formatValue(
                        detailsData?.serviceCharge,
                        true,
                        detailsData?.currency,
                        locale
                      ),
                    },
                  ]
                : []),
              ...(detailsData?.gratuity !== undefined && detailsData?.gratuity !== 0
                ? [
                    {
                      label: t('screens.transactions.fields.gratuity'),
                      value: formatValue(
                        detailsData?.gratuity,
                        true,
                        detailsData?.currency,
                        locale
                      ),
                      /* We don't apply the !== undefined check here, since if gratuity is 0 we want to hide the field, unlike the total that can be 0 and still must be displayed */
                    },
                  ]
                : []),
              ...(detailsData?.giftTreeAmount !== undefined && detailsData?.giftTreeAmount !== 0
                ? [
                    {
                      label: t('screens.transactions.fields.giftTree'),
                      value: formatValue(
                        detailsData?.giftTreeAmount,
                        true,
                        detailsData?.currency,
                        locale
                      ),
                    },
                  ]
                : []),
              ...getDiscounts(),
              {
                label: t('screens.transactions.fields.total'),
                value:
                  detailsData?.total !== undefined &&
                  formatValue(detailsData?.total, true, detailsData?.currency, locale),
                sx: { fontWeight: 600 },
              },
            ],
            { borderBottom: sepColor, paddingTop: '16px', paddingBottom: '16px' }
          )}
        {!detailsFetching && (detailsData?.payments || detailsData?.loyaltyPointsDiscount) ? (
          getTwoColumnTable(
            [
              {
                label: t('screens.transactions.fields.payment'),
                value: t('screens.transactions.fields.amount'),
                sx: { fontWeight: 600 },
              },
              ...(detailsData && detailsData.payments
                ? legacyFlatMap(detailsData.payments, (payment) => [
                    {
                      label: `${payment.method} ${payment.lastFourDigits}`,
                      value:
                        payment.amount !== undefined &&
                        formatValue(payment.amount || 0, true, detailsData?.currency, locale),
                    },
                    {
                      label: `# ${payment.transactionID}`,
                      value: payment.date !== 'TBD' && formatDate(payment.date, locale),
                      sx: { fontSize: '12px !important', color: 'grey', marginTop: '-4px' },
                    },
                  ])
                : []),
              ...(detailsData && detailsData.loyaltyPointsDiscount
                ? [
                    {
                      label: t('screens.transactions.fields.loyaltyPoints'),
                      value: formatValue(
                        detailsData?.loyaltyPointsDiscount || 0,
                        true,
                        detailsData?.currency,
                        locale
                      ),
                    },
                  ]
                : []),
              ...(detailsData?.change
                ? [
                    {
                      label: t('screens.transactions.fields.change'),
                      value: formatValue(detailsData?.change, true, detailsData?.currency, locale),
                    },
                  ]
                : []),
            ],
            { borderBottom: sepColor, paddingTop: '16px', paddingBottom: '16px' }
          )
        ) : (
          <span />
        )}
        {!detailsFetching &&
          detailsData?.taxRates &&
          getTaxRateTable(detailsData?.taxRates, detailsData?.currency, {
            paddingTop: '16px',
            paddingBottom: '16px',
          })}
        {!detailsFetching &&
          detailsData?.covers &&
          getTwoColumnTable(
            detailsData?.covers?.map((cover, idx) => ({
              label: `${t('screens.transactions.fields.cover')} ${(idx + 1).toString(10)}`,
              value: formatValue(cover, true, detailsData?.currency, locale),
            })),
            { borderBottom: sepColor, paddingBottom: '16px' },
            t('screens.transactions.fields.covers')
          )}
      </TableContainer>
    );
  }

  function getErrorBody() {
    return (
      <TableContainer
        component={Box}
        sx={{
          position: 'absolute',
          height: 'calc(100% - 72px)',
          width: '100%',
          overflowY: 'auto' as const,
          overflowX: 'hidden' as const,
        }}
      >
        <Box
          sx={{
            height: '400px',
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconMessage
            title={t('components.iconMessage.failedToLoad.title')}
            icon={IconMessageIcon.ERROR}
            subtitle={t('components.iconMessage.failedToLoad.subtitle')}
            buttonText={t('components.iconMessage.failedToLoad.buttonText')}
            onClickButton={fetchDetails}
          />
        </Box>
      </TableContainer>
    );
  }

  const auth = useAuth();
  const maybeAccessToken = auth?.userData?.access_token;
  const { persistSettings } = useContext(SettingsContext);

  const feedbackHandler = useCallback(
    async (form: ContactForm) => {
      if (!transactionId) return { success: false, error: 'no transaction id' };
      try {
        const feedbackId = v4();
        const payload = {
          category: form.topic || '',
          customerComment: form.message || '',
          frontendSentryTracingId: feedbackId,
        };
        await flagTransaction(transactionId || '', payload, maybeAccessToken, persistSettings);
        setFeedbackSent(payload);
        // TL;DR: don't remove this call
        // we should refetch the data to grab the report with the updated feedback, since react-query caches it
        // and then when we go again to details it shows it has no feedback
        // the timeout is to ensure the updated data is stored in opensearch since it has a slight delay (eventual consistency)
        // there is no perfect formula for this and it may happen that due to high network lag 1 second is still not enough
        // in any case that would only have an effect if cx closes the details panel and opens it again,
        // but we'd detect the inconsistency, show an error and it would work on page reload.
        setTimeout(fetchDetails, 1000);
        Sentry.captureMessage('Customer feedback report emitted', {
          extra: {
            feedbackId,
            transactionId,
            category: form.topic,
            customerComment: form.message,
            environment: process.env.REACT_APP_ENVIRONMENT,
            source: 'TransactionReportDetailsPanel',
          },
        });
        return { success: true };
      } catch (error) {
        return { success: false, error: t('components.error.somethingFailed') };
      }
    },
    [t, maybeAccessToken, persistSettings, setFeedbackSent, transactionId, fetchDetails]
  );

  const retractHandler = useCallback(async () => {
    if (!transactionId) return { success: false, error: 'no transaction id' };
    await unflagTransaction(
      transactionId || '',
      maybeAccessToken,
      persistSettings,
      adminView ? companyId : undefined
    );

    // we should refetch the data to grab the report with the updated feedback, since react-query caches it
    // and then when we go again to details it shows it still has feedback
    setFeedbackSent(undefined);
    setTimeout(fetchDetails, 1000);
    setTimeout(refetchTable, 1000);
    return { success: true };
  }, [
    setFeedbackSent,
    maybeAccessToken,
    persistSettings,
    transactionId,
    fetchDetails,
    adminView,
    companyId,
    refetchTable,
  ]);

  const bottomToolbar = (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: (isMobile || isTablet) ? 'column' : 'row-reverse',
        marginLeft: 'auto',
        padding: (isMobile || isTablet) ? 0 : '16px'
      }}>
      {transactionId && (
        <TransactionFeedbackButton
          feedbackSent={feedbackSent}
          feedbackHandler={feedbackHandler}
          retractHandler={retractHandler}
          adminView={adminView}
          startView={startOnFeedback ? 'viewFeedback' : undefined}
        />
      )}
    </Box>
  );

  return (
    <TitledDrawer
      title={t('screens.transactions.details.title')}
      icon={
        !isMobile && !isTablet ?
        <ReceiptLong
          sx={{
            color: 'text.secondary',
          }}
        /> :
        <ArrowBack
          sx={{
            color: 'text.secondary',
          }}
        />
      }
      iconRight={
        !isMobile && !isTablet ?
        <CloseOutlined
          sx={{
            color: 'text.secondary',
          }}
        /> :
        <ReceiptLong
          sx={{
            color: 'text.secondary',
          }}
        />
      }
      onClose={onClose}
      bottomToolbar={bottomToolbar}
      sx={{
        bottomToolbar: { padding: '8px' },
        scrollContext: { height: 'calc(100% - 138px)' },
      }}
    >
        {detailsError ? getErrorBody() : getBody()}
    </TitledDrawer>
  );
};

export default TransactionReportDetailsPanel;
