/* eslint-disable import/prefer-default-export */
import { ApiFetchParams, ApiQueryOptions, useApiQuery } from "../helpers/useApi";
import { TroncLocationFilters, TroncSummary } from "../models/tronc";

export type GetTroncRequest = {
    startDate: string;
    endDate: string;
    location: string;
}
 
export interface TroncApiResponse<T> {
    currency: string;
    summary: TroncSummary;
    troncRows: T[];
}

export function useTroncs<T>(params: GetTroncRequest, queryKey: string, options?: ApiQueryOptions<TroncApiResponse<T>>) {
    return useApiQuery<TroncApiResponse<T>>({
        path: `/data/reporting/v1/tronc?${new URLSearchParams(JSON.parse(JSON.stringify(params)))}`,
        body: {}
    } as ApiFetchParams, queryKey, options);
}

export function useLocationFilters(queryKey: string, options: ApiQueryOptions<TroncLocationFilters>) {
    return useApiQuery<TroncLocationFilters>({
        path: `/data/reporting/v1/tronc/filters`,
        body: {}
    } as ApiFetchParams, queryKey, options);
}
