import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { AuthProvider } from 'oidc-react';
import AppRoutes from './AppRoutes';
import { SettingsContextProvider } from './context/SettingsContext';
import { StorageContextProvider } from './context/StorageContext';
import ThemeContext from './context/ThemeContext';
import { TransactionFiltersContextProvider } from './context/TransactionFiltersContext';
import { UIContextProvider } from './context/UIContext';
import { UserContextProvider } from './context/UserContext';
import { getBaseDomain } from './helpers/helpers';
import Maintenance from './Maintenance';
import LoggedOut from './screens/loggedOut/LoggedOut';
import './i18n/i18n';
import NavigationComponents from './components/NavigationComponents';
import WithScrollbars from './screens/transactions/components/WithScrollbars';
import ReportingAccessVerification from './components/ReportingAccessVerification';
import { TroncFiltersContextProvider } from './context/TroncFiltersContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 60 * 60 * 1000, // @TODO change to a different time
    },
  },
});

const baseDomain = getBaseDomain(window.location.href);

const App = (props: { oidcConfig: object }) => {
  const maintenanceMode = process.env.REACT_APP_MAINTENANCE_ENABLED === 'true';
  const { oidcConfig } = props;
  return !maintenanceMode ? (
    <CookiesProvider defaultSetOptions={{ domain: `.${baseDomain}` }}>
      <UIContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <ThemeContext>
            <QueryClientProvider client={queryClient}>
              <Router>
                <Routes>
                  <Route path="/" element={null} />
                  <Route path="/logged-out" element={<LoggedOut />} />
                </Routes>
              </Router>
              <AuthProvider {...oidcConfig}>
                <React.StrictMode>
                  <StorageContextProvider>
                    <SettingsContextProvider>
                      <UserContextProvider>
                        <TransactionFiltersContextProvider>
                          <TroncFiltersContextProvider>
                            <Router>
                              <Box
                                sx={{
                                  display: 'flex',
                                  position: 'absolute',
                                  width: '100%',
                                  height: '100%',
                                }}
                                >
                                <NavigationComponents />
                                <WithScrollbars
                                  style={{
                                    marginLeft: 0,
                                    flexGrow: 1,
                                    paddingLeft: '64px !important',
                                    paddingRight: '64px !important',
                                  }}
                                  >
                                  <Container
                                    component="main"
                                    id="main"
                                    maxWidth={false}
                                    disableGutters
                                    sx={{
                                      p: { xs: 1, sm: 3 },
                                      width: '100%',
                                      height: 'calc(100vh - 64px)',
                                      marginTop: '64px',
                                      paddingBottom: '24px',
                                      display: 'flex',
                                      flexFlow: 'column'
                                    }}
                                  >
                                    <ReportingAccessVerification>
                                      <AppRoutes />
                                    </ReportingAccessVerification>
                                  </Container>
                                </WithScrollbars>
                              </Box>
                            </Router>
                          </TroncFiltersContextProvider>
                        </TransactionFiltersContextProvider>
                      </UserContextProvider>
                    </SettingsContextProvider>
                  </StorageContextProvider>
                </React.StrictMode>
              </AuthProvider>
            </QueryClientProvider>
          </ThemeContext>
        </LocalizationProvider>
      </UIContextProvider>
    </CookiesProvider>
  ) : (
    <Maintenance />
  );
};

export default App;
