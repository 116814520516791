import { format } from 'date-fns';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import React, {CSSProperties, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';
import {UIContext} from '../../../context/UIContext';
import {ReactComponent as WhiteSuccessIcon} from '../../../img/success-white.svg';
import {ReactComponent as SuccessIcon} from '../../../img/success.svg';
import objectArrayToCSV from '../../../helpers/objectArrayToCSV';
import { TroncRow } from '../../../models/tronc';


export interface ExportDialogProps {
  start: boolean;
  onComplete: () => void;
  rows: TroncRow[];
}

const contentDesktopStyle: CSSProperties = { textAlign: 'center', width: '600px' };
const contentMobileStyle: CSSProperties = {textAlign: 'center', marginTop: '8px'};
const cardDesktopStyle: CSSProperties = {
  textAlign: 'right',
  justifyContent: 'flex-start',
  width: '340px',
  position: 'relative',
  left: 'calc(50% - 160px)',
  marginBottom: '32px',
};
const cardMobileStyle: CSSProperties = { width: '100%'}

export const ExportDialog = (props: ExportDialogProps) => {
  const { start, onComplete, rows } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filename, setFilename] = useState<string>();
  const { colorMode, isMobile, isTablet } = useContext(UIContext);

  const contentStyle = (isMobile || isTablet) ? contentMobileStyle : contentDesktopStyle;
  const cardStyle = (isMobile || isTablet) ? cardMobileStyle : cardDesktopStyle;

  const createFileUri = () => {
    const excludeColumns: (keyof TroncRow)[] = [
      "noOfShiftRecords"
    ];
    
    const transformer = (key: string, val: unknown) => {
      if (key === "hours") return (val as number ?? 0).toFixed(2);
      if (key === "gratuity") return (val as number ?? 0).toFixed(2);
      if (key === "serviceCharge") return (val as number ?? 0).toFixed(2);
      if (key === "totalTronc") return (val as number ?? 0).toFixed(2);
      return val;
    };
    
    const blob = new Blob([objectArrayToCSV(rows, ",", "/", excludeColumns, transformer)], {type: "application/octet-stream"});
    return URL.createObjectURL(blob);
  };

  function generateNewFilename() {
      const ts = format(new Date(), 'yyyyMMdd_kkmmss');
      setFilename(`${t('export.troncReport')}_${ts}.csv`);
  }

  useEffect(() => {
      if (!start) {
          setFilename(undefined);
          return;
      }
      generateNewFilename();
      setOpen(true);
  }, [start]);

  const onClose = () => {
      setOpen(false);
      if (onComplete) {
          onComplete();
      }
      setFilename(undefined);
  }

  const onDownload = () => {
    // create a fake link to initiate a download
    const link = document.createElement('a');
    link.href = createFileUri();
    link.setAttribute('target', '_blank');
    link.setAttribute('download', filename || 'export.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '650px',
          borderRadius: (isMobile || isTablet) ? 0 : '8px'
        }
      }}
      fullScreen={isMobile || isTablet}
    >
      <Box
        sx={
          (isMobile || isTablet) ? {display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'} : {}
        }
      >
      {(isMobile || isTablet) &&
        <ArrowBack
          sx={{margin: '16px'}}
          onClick={onClose}
        />
      }
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 600, 
          marginTop: (isMobile || isTablet) ? 0 :'32px'
        }}
      >
        {colorMode === 'light' ? <SuccessIcon fontSize="56px"/> : <WhiteSuccessIcon fontSize="56px"/>}
        <br />
        {t('export.exportingTronc')}
      </DialogTitle>
      <DialogContent sx={contentStyle}>
        {!isMobile && !isTablet &&
          <IconButton
              sx={{position: 'absolute', top: '8px', left: '600px', color: "text.secondary"}}
              onClick={onClose}
          >
            <CloseOutlinedIcon />
          </IconButton>
        }
        <DialogContentText sx={(isMobile || isTablet) ? { marginBottom: '24px' } : {marginBottom: '8px'}}>
          {t('export.yourReportIsAvailable')}
          <br />
          {t('export.itIsNowReady')}
        </DialogContentText>
        <Box sx={(isMobile || isTablet) ? {marginLeft: '8px', marginRight: '8px'} : {}}>
          <Card
            variant="outlined"
            sx={cardStyle}
          >
            <CardContent sx={{padding: '16px !important'}}>
              <div style={{ display: 'flex', textAlign: 'left', justifyContent: 'center' }}>
                {colorMode === 'light' ?
                  <SuccessIcon style={{ width: "20px", height: "20px", marginRight: "10px" }}/> :
                  <WhiteSuccessIcon fontSize="20px" style={{ width: "20px", height: "20px", marginRight: "10px" }}/>
                }
                <Typography component="span" sx={{ fontWeight: 400, fontSize: '14px' }}>
                  {filename || 'export.csv'}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Box>
        <DialogActions>
          <Button sx={(isMobile || isTablet) ? {width: '100%'} : {}} variant="contained" size="large" component="a" onClick={onDownload}>
            {t('actions.download')}
          </Button>
        </DialogActions>
      </DialogContent>
      </Box>
    </Dialog>
  );
};
