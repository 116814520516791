import { ArrowBack, FilterList } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputAdornmentProps,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAdvancedFilters } from '../../../api/transactions';
import TitledDrawer from '../../../components/TitledDrawer';
import {
  comboStyle,
  desktopButtonStyle,
  desktopSecondaryButtonStyle,
  mobileButtonStyle,
  mobileSecondaryButtonStyle,
  transactionFeedbackOptions,
} from '../../../constants';
import { TransactionFiltersContext } from '../../../context/TransactionFiltersContext';
import { TInputDateToString } from '../../../helpers/dateUtils';
import { defer } from '../../../helpers/helpers';
import { TransactionReportFilters } from '../../../models/transactions';
import { UIContext } from '../../../context/UIContext';

const subHeaderStyle: CSSProperties = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
};

const TransactionReportFiltersPanel = (props: { adminView?: boolean }) => {
  const { adminView } = props;
  const {
    filters,
    unsavedFilters,
    setFilters,
    applyFilters,
    filtersChanged,
    resetFilters,
    closeFilters,
    validationErrors,
    dateRangeConfig,
  } = useContext(TransactionFiltersContext);

  const theme = useTheme();
  const { isMobile, isTablet } = useContext(UIContext);
  const buttonStyle = (isMobile || isTablet) ? mobileButtonStyle : desktopButtonStyle;
  const secondaryButtonStyle = isMobile ? mobileSecondaryButtonStyle : desktopSecondaryButtonStyle;

  const { t } = useTranslation();

  const bottomToolbar = (
    <Box>
      <Box
        sx={{
          display: 'flex', 
          flexDirection: (isMobile || isTablet) ? 'column' : 'row-reverse',
          marginLeft: 'auto',
          padding: '16px'
        }}
      >
        <Button
          variant="contained"
          sx={[buttonStyle]}
          onClick={() => {
            if (applyFilters()) defer(() => closeFilters(true));
          }}
          data-qa-id="filterApplyButton"
        >
          {t('components.filters.apply')}
        </Button>
        {filtersChanged && (
          <Button
            variant="text"
            sx={{ ...secondaryButtonStyle }}
            onClick={resetFilters}
            data-qa-id="filterResetButton"
          >
            {t('components.filters.reset')}
          </Button>
        )}
      </Box>
    </Box>
  );

  const errorsFor = useCallback(
    (key: string) =>
      validationErrors
        .filter((it) => it.field === key)
        .map((it) => it.message)
        .join(', '),
    [validationErrors]
  );
  const hasErrors = useCallback((key: string) => errorsFor(key).length > 0, [errorsFor]);
  const onChangePeriod = useCallback(
    (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setFilters(
        (prevFilters: TransactionReportFilters) =>
          ({
            ...prevFilters,
            period: ev.target.value,
          } as TransactionReportFilters)
      ),
    [setFilters]
  );
  const onChangePeriodFrom = useCallback(
    (date: Date | undefined | null) =>
      setFilters(
        (prevFilters: TransactionReportFilters) =>
          ({
            ...prevFilters,
            periodFrom: TInputDateToString(date),
          } as TransactionReportFilters)
      ),
    [setFilters]
  );
  const onChangePeriodTo = useCallback(
    (date: Date | undefined | null) =>
      setFilters(
        (prevFilters: TransactionReportFilters) =>
          ({
            ...prevFilters,
            periodTo: TInputDateToString(date),
          } as TransactionReportFilters)
      ),
    [setFilters]
  );
  const [openPeriodFrom, setOpenPeriodFrom] = useState(false);
  const [openPeriodTo, setOpenPeriodTo] = useState(false);

  const pickerFrom = useMemo(
    () => (unsavedFilters.periodFrom ? new Date(unsavedFilters.periodFrom) : new Date()),
    [unsavedFilters.periodFrom]
  );
  const pickerTo = useMemo(
    () => (unsavedFilters.periodTo ? new Date(unsavedFilters.periodTo) : new Date()),
    [unsavedFilters.periodTo]
  );
  const customStartPicker = useCallback(
    (p: InputAdornmentProps) => (
      <InputAdornment {...p} position="end" onClick={() => setOpenPeriodFrom(true)}>
        <IconButton aria-label="Period from (show calendar)" sx={{ left: '12px' }}>
          {hasErrors('periodFrom') ? <ErrorOutlineIcon color="error" /> : <EventOutlinedIcon />}
        </IconButton>
      </InputAdornment>
    ),
    [setOpenPeriodFrom, hasErrors]
  );
  const customEndPicker = useCallback(
    (p: InputAdornmentProps) => (
      <InputAdornment {...p} position="end" onClick={() => setOpenPeriodTo(true)}>
        <IconButton aria-label="Period to (show calendar)" sx={{ left: '12px' }}>
          {hasErrors('periodTo') ? <ErrorOutlineIcon color="error" /> : <EventOutlinedIcon />}
        </IconButton>
      </InputAdornment>
    ),
    [setOpenPeriodTo, hasErrors]
  );

  const fireResize = useCallback(() => {
    if (typeof Event === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      // for IE and other old browsers
      // causes deprecation warning on modern browsers
      const evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    }
  }, []);

  const { data: filtersData, refetch: fetchFilters } = useAdvancedFilters(
    `transactionFilters${adminView ? 'Admin' : ''}`,
    {},
    unsavedFilters.companyGuid || undefined
  );

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters, unsavedFilters.companyGuid]);

  return (
    <TitledDrawer
      title={t('components.filters.filters')}
      onClose={() => closeFilters()}
      icon={
        !isMobile && !isTablet ?
        <FilterList
          sx={{
            color: 'text.secondary',
          }}
        /> :
        <ArrowBack
          sx={{
            color: 'text.secondary',
          }}
        />
      }
      bottomToolbar={bottomToolbar}
      bottomToolbarMarginTop='16px'
      iconRight={(isMobile || isTablet) ?
        <FilterList
          sx={{
            color: 'text.secondary',
          }}
        /> : undefined
      }
    >
      <Grid
        item
        sx={{
          paddingLeft: '4px',
          paddingRight: '4px'
        }}
      >
        <Accordion
          elevation={0}
          defaultExpanded
          disableGutters
          square
          sx={{
            padding: 0,
            border: 0,
            margin: 0,
          }}
          onClick={() => setTimeout(fireResize, 250)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="timeFilters-content"
            sx={{ padding: 0, border: 0, margin: 0 }}
            id="timeFilters-header"
            data-qa-id="timeFilterHeader"
          >
            <Typography sx={subHeaderStyle} variant="h3">
              {t('components.filters.time')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, border: 0, margin: 0 }}>
            <TextField
              size="small"
              sx={comboStyle}
              select
              label={t('components.datePicker.timePeriod')}
              value={unsavedFilters.period}
              id="period"
              onChange={onChangePeriod}
              inputProps={{'data-qa-id':"filterPeriodOption"}}
            >
              <MenuItem value="today">{t('components.datePicker.today')}</MenuItem>
              <MenuItem value="yesterday">{t('components.datePicker.yesterday')}</MenuItem>
              <MenuItem value="thisWeek">{t('components.datePicker.thisWeek')}</MenuItem>
              <MenuItem value="lastWeek">{t('components.datePicker.lastWeek')}</MenuItem>
              <MenuItem value="thisMonth">{t('components.datePicker.thisMonth')}</MenuItem>
              <MenuItem value="lastMonth">{t('components.datePicker.lastMonth')}</MenuItem>
              <MenuItem value="custom">{t('components.datePicker.custom')}</MenuItem>
            </TextField>
            <DateTimePicker
              label={t('components.datePicker.startDate')}
              value={pickerFrom}
              onChange={onChangePeriodFrom}
              open={openPeriodFrom}
              onOpen={() => setOpenPeriodFrom(true)}
              onClose={() => setOpenPeriodFrom(false)}
              slots={{
                inputAdornment: customStartPicker,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  error: hasErrors('periodFrom'),
                  helperText:
                    errorsFor('periodFrom') ||
                    `${t('components.datePicker.aFullDayIsFrom')} ${dateRangeConfig.daysStartAt
                      .toString(10)
                      .padStart(2, '0')}:00 ${t(
                      'components.datePicker.to'
                    )} ${dateRangeConfig.daysStartAt.toString(10).padStart(2, '0')}:00`,
                  style: comboStyle,
                  inputProps: {
                    'data-qa-id': "filterStartDateTime"
                  }
                },
              }}
              format={t('components.datePicker.dateTimeFormat')}
            />
            <DateTimePicker
              label={t('components.datePicker.endDate')}
              value={pickerTo}
              onChange={onChangePeriodTo}
              open={openPeriodTo}
              onOpen={() => setOpenPeriodTo(true)}
              onClose={() => setOpenPeriodTo(false)}
              format={t('components.datePicker.dateTimeFormat')}
              slots={{
                inputAdornment: customEndPicker,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  error: hasErrors('periodTo'),
                  style: comboStyle,
                  helperText:
                    errorsFor('periodTo') ||
                    `${t('components.datePicker.aFullDayIsFrom')} ${dateRangeConfig.daysStartAt
                      .toString(10)
                      .padStart(2, '0')}:00 ${t(
                      'components.datePicker.to'
                    )} ${dateRangeConfig.daysStartAt.toString(10).padStart(2, '0')}:00`,
                  inputProps: {
                    'data-qa-id': "filterStartDateTime"
                  }
                },
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          square
          disableGutters
          sx={{ padding: 0, border: 0, margin: 0 }}
          onClick={() => setTimeout(fireResize, 250)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ padding: 0, border: 0, margin: 0 }}
            aria-controls="areaFilters-content"
            id="areaFilters-header"
            data-qa-id="areaFilterHeader"
          >
            <Typography sx={subHeaderStyle} variant="h3">
              {t('components.filters.area')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, border: 0, margin: 0 }}>
            <TextField
              size="small"
              select
              value={
                unsavedFilters.locationArea ? JSON.stringify(unsavedFilters.locationArea) : 'all'
              }
              label={t('components.filters.locationArea')}
              id="locationArea"
              sx={comboStyle}
              error={hasErrors('locationArea')}
              helperText={errorsFor('locationArea')}
              onChange={(ev) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  locationArea: ev.target.value === 'all' ? undefined : JSON.parse(ev.target.value),
                }))
              }
              inputProps={{'data-qa-id':"filterLocationAreaOption"}}
            >
              <MenuItem value="all">{t('components.filters.allLocationAreas')}</MenuItem>
              {filtersData &&
                filtersData.locationAreas &&
                filtersData.locationAreas.map((la) => (
                  <MenuItem value={JSON.stringify(la)} key={la.id}>
                    {la.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              size="small"
              select
              value={unsavedFilters.location ? JSON.stringify(unsavedFilters.location) : 'all'}
              label={t('components.filters.location')}
              id="location-label"
              sx={comboStyle}
              error={hasErrors('location')}
              helperText={errorsFor('location')}
              onChange={(ev) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  location: ev.target.value === 'all' ? undefined : JSON.parse(ev.target.value),
                }))
              }
              inputProps={{'data-qa-id':"filterLocationOption"}}
            >
              <MenuItem value="all">{t('components.filters.allLocations')}</MenuItem>
              {filtersData &&
                filtersData.locations &&
                filtersData.locations.map((location) => (
                  <MenuItem value={JSON.stringify(location)} key={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
            </TextField>
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          square
          disableGutters
          sx={{ padding: 0, border: 0, margin: 0 }}
          onClick={() => setTimeout(fireResize, 250)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="advancedFilters-content"
            sx={{ padding: 0, border: 0, margin: 0 }}
            id="advancedFilters-header"
            data-qa-id="advancedFilterHeader"
          >
            <Typography sx={subHeaderStyle} variant="h3">
              {t('components.filters.advanced')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, border: 0, margin: 0 }}>
            <TextField
              size="small"
              select
              value={unsavedFilters.device ? JSON.stringify(unsavedFilters.device) : 'all'}
              label={t('components.filters.device')}
              id="device-label"
              sx={comboStyle}
              error={hasErrors('device')}
              helperText={errorsFor('device')}
              onChange={(ev) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  device: ev.target.value === 'all' ? undefined : JSON.parse(ev.target.value),
                }))
              }
              inputProps={{'data-qa-id':"filterDeviceOption"}}
            >
              <MenuItem value="all">{t('components.filters.allDevices')}</MenuItem>
              {filtersData &&
                filtersData.devices &&
                filtersData.devices.map((device) => (
                  <MenuItem value={JSON.stringify(device)} key={device.id}>
                    {device.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              size="small"
              select
              value={
                unsavedFilters.paymentMethod ? JSON.stringify(unsavedFilters.paymentMethod) : 'all'
              }
              label={t('components.filters.paymentMethod')}
              id="paymentMethod-label"
              sx={{ ...comboStyle }}
              error={hasErrors('paymentMethod')}
              helperText={errorsFor('paymentMethod')}
              onChange={(ev) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  paymentMethod:
                    ev.target.value === 'all' ? undefined : JSON.parse(ev.target.value),
                }))
              }
              inputProps={{'data-qa-id':"filterPaymentMethodOption"}}
            >
              <MenuItem value="all">{t('components.filters.allPaymentMethods')}</MenuItem>
              {filtersData &&
                filtersData.tenderTypes &&
                filtersData.tenderTypes.map((pm) => (
                  <MenuItem value={JSON.stringify(pm)} key={pm.id}>
                    <ListItemText
                      sx={{
                        margin: 0,
                        float: 'left',
                      }}
                    >
                      {pm.name}
                    </ListItemText>
                    {pm.description && pm.description.trim() !== pm.name.trim() && (
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          marginTop: '2px',
                          float: 'right',
                        }}
                      >
                        {pm.description}
                      </Typography>
                    )}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              size="small"
              select
              value={unsavedFilters.staff ? JSON.stringify(unsavedFilters.staff) : 'all'}
              label={t('components.filters.staff')}
              id="staff-label"
              sx={comboStyle}
              error={hasErrors('staff')}
              helperText={errorsFor('staff')}
              onChange={(ev) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  staff: ev.target.value === 'all' ? undefined : JSON.parse(ev.target.value),
                }))
              }
              inputProps={{'data-qa-id':"filterStaffOption"}}
            >
              <MenuItem value="all">{t('components.filters.allStaff')}</MenuItem>
              {filtersData &&
                filtersData.staff &&
                filtersData.staff.map((sta) => (
                  <MenuItem value={JSON.stringify(sta)} key={sta.id}>
                    {sta.name}
                  </MenuItem>
                ))}
            </TextField>
          </AccordionDetails>
        </Accordion>
        {adminView && (
          <Accordion
            elevation={0}
            square
            disableGutters
            sx={{ padding: 0, border: 0, margin: 0 }}
            onClick={() => setTimeout(fireResize, 250)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="adminFilters-content"
              sx={{ padding: 0, border: 0, margin: 0 }}
              id="adminFilters-header"
              data-qa-id="adminFilterHeader"
            >
              <Typography sx={subHeaderStyle} variant="h3">
                {t('components.filters.admin')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, border: 0, margin: 0 }}>
              {/* eslint-disable react/jsx-no-duplicate-props */}
              <TextField
                size="small"
                select
                value={
                  unsavedFilters.activeFlagCategory ||
                  'allFlagged'
                }
                label={t('components.filters.feedbackType')}
                id="flagCategory-label"
                sx={comboStyle}
                error={hasErrors('flagCategory')}
                helperText={errorsFor('flagCategory')}
                onChange={(ev) =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    hasActiveFlag: true,
                    activeFlagCategory:
                      ev.target.value !== 'allFlagged'
                        ? ev.target.value
                        : undefined,
                  }))
                }
                inputProps={{'data-qa-id':"filterFlagCategoryOption"}}
              >
                <MenuItem value="allFlagged">
                  {t('components.filters.allFlaggedTransactions')}
                </MenuItem>
                <ListSubheader>{t('components.filters.categories')}</ListSubheader>
                {Object.keys(transactionFeedbackOptions).map((key) => (
                  <MenuItem value={key} key={key}>
                    {t(`screens.transactions.feedback.options.${key}`)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                value={unsavedFilters.companyGuid || ''}
                label={t('components.filters.companyId')}
                id="companyId-label"
                sx={comboStyle}
                error={hasErrors('companyGuid')}
                helperText={errorsFor('companyGuid')}
                onChange={(ev) =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    companyGuid: ev.target.value,
                  }))
                }
                inputProps={{ maxLength: 36, 'data-qa-id':"filterCompanyGuidField" }}
                InputProps={{
                  endAdornment:
                    unsavedFilters &&
                    unsavedFilters.companyGuid &&
                    unsavedFilters.companyGuid.length > 0 ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              companyGuid: undefined,
                            }))
                          }
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </TitledDrawer>
  );
};

export default TransactionReportFiltersPanel;
