import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ArrowDownward, ArrowUpward, ChevronRight, FilterList } from '@mui/icons-material';
import { Button, Box, Typography, debounce, Drawer, Modal, Dialog } from '@mui/material';
import { GridSortDirection } from "@mui/x-data-grid-pro";
import { useTranslation } from 'react-i18next';
import { formatTime, isToday, isYesterday } from '../../../helpers/dateUtils';
import { AssociativeArray, defer, formatValue, stableSort } from '../../../helpers/helpers';
import { TransactionSummary } from '../../../models/transactions';
import { UIContext } from '../../../context/UIContext';
import { UserContext } from '../../../context/UserContext';
import { getComparator, ROWS_PER_PAGE_MOBILE } from '../constants';
import { EnhancedTableToolbar } from '../../../components/EnhancedTableToolbar';
import { TransactionFiltersContext } from '../../../context/TransactionFiltersContext';
import TransactionReportFiltersPanel from './TransactionReportFiltersPanel';
import TransactionReportDetailsPanel from './TransactionReportDetailsPanel';
import FailedToLoad from './FailedToLoad';
import Loading from './Loading';
import NoTransactions from './NoTransactions';
import { REPORT_TYPE_DETAILS, REPORT_TYPE_SUMMARY, TransactionsReportType } from '../../../api/transactions';
import { ExportDialogWithApi } from './ExportDialogWithApi';

interface ReportListMobileProps {
    refetch: () => void;
    refresh: () => void;
    page: number;
    setPage: (p: number) => void;
    setRowsPerPage: (p: number) => void;
    order: GridSortDirection;
    setOrder: (o: GridSortDirection) => void;
    orderBy: string;
    setOrderBy: (c: string) => void;
    search: string;
    setSearch: (s: string) => void;
    count: number;
    isLoading: boolean;
    isError: boolean;
    adminView?: boolean;
    totalRows: TransactionSummary[];
    emptyTotalRows: () => void;
}

const DESC: GridSortDirection = 'desc';
const ASC: GridSortDirection = 'asc';

const ReportListMobile = ({
    refetch,
    refresh,
    page,
    setPage,
    setRowsPerPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    search,
    setSearch,
    count,
    isLoading,
    isError,
    adminView,
    totalRows,
    emptyTotalRows
  } : ReportListMobileProps) => {
    const { t } = useTranslation();
    const { colorMode } = useContext(UIContext);
    const { locale } = useContext(UserContext);
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const [isDetailsOpen, setDetailsOpen] = useState(false);
    const [companyId, setCompanyId] = useState<string | undefined>(undefined);
    const [searchInputValue, setSearchInputValue] = useState(search);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);
    const [exportReportType, setExportReportType] = useState<TransactionsReportType>(REPORT_TYPE_SUMMARY);
    const [exportReportStarted, setExportReportStarted] = useState(false);
    const { filters, filtersOpen, openFilters, dateRangeConfig } =
    useContext(TransactionFiltersContext);
    const filtersButtonRef = useRef(null);

    useEffect(() => {
      setOrderBy('date' as keyof TransactionSummary);
      setOrder(DESC);
    }, []);

    const debouncedSearch = useMemo(() => debounce(setSearch, 500), [setSearch]);
    const handleChangeSearch = useCallback((newSearchValue: string) => {
      setSearchInputValue(newSearchValue);
      debouncedSearch(newSearchValue);
    }, [debouncedSearch, setSearchInputValue]);

    const handleClick = useCallback(
        (event: React.MouseEvent<unknown>, rowId: string, company?: string) =>
          defer(() => {
            setSelected(rowId);
            setCompanyId(company);
            setDetailsOpen(true);
          }),
        [setSelected, setDetailsOpen]
      );

      const handleSortOrderChange = useCallback((sortOrder: GridSortDirection) => {
            emptyTotalRows();
            setPage(0);
            setOrder(sortOrder);
            defer(refetch);
      }, [order, setOrder, refetch]
    );

      const getMoreTransactions = useCallback(
        (event: unknown, newPage: number) => {
          setRowsPerPage(ROWS_PER_PAGE_MOBILE);
          setPage(newPage);
          // defer the call to the bottom of the stack so the updates to the props
          // are applied
          defer(refetch);
        },
        [setPage, refetch]
      );

      const exportOnComplete = useCallback(() => {
        setExportReportStarted(false);
    }, [setExportReportStarted]
);

      const exportFilteredTransactions = useCallback((withDetails = false) => {
            if (exportReportStarted) {
              return;
            }
            setExportReportType(withDetails ? REPORT_TYPE_DETAILS : REPORT_TYPE_SUMMARY);
            setExportReportStarted(true);
          },
          [exportReportStarted, setExportReportStarted, setExportReportType]
      );

      const toggleModal = () => setModalOpen(!modalOpen);
      const handleCloseModal = (event: unknown, reason: string) => {
          if (reason && reason === "backdropClick") setModalOpen(false);
      }
    const getTransactionMobileCard = (labelId: string, row: TransactionSummary, isLastRow: boolean) =>
    (
        <Box key={`mobile-card-outer-${labelId}`}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            justifyContent: 'space-between',
            paddingY: '1rem',
            paddingLeft: '1rem',
            backgroundColor: (theme) => colorMode === 'light' ? theme?.palette?.common?.white : theme?.palette?.common?.black,
            borderTop: (theme) =>
                `1px ${
                colorMode === 'light' ? theme?.palette?.grey?.['12p'] : theme?.palette?.grey?.['23p']
                } solid`,
            borderBottom: isLastRow ? 0 : (theme) => `1px ${
                colorMode === 'light' ? theme?.palette?.grey?.['12p'] : theme?.palette?.grey?.['23p']
                } solid`

        }}>
            <Box key={`mobile-card-first-row-${labelId}`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
            {row.itemsSold &&<Typography key={`mobile-card-items-${labelId}`}
                sx={{
                fontSize: '16px',
            }}
            >{row.itemsSold === 1 ?
                t('screens.transactions.fields.item', {items: row.itemsSold}) :
                t('screens.transactions.fields.items', {items: row.itemsSold})}</Typography>}
            <Typography key={`mobile-card-amount-${labelId}`}
            sx={{
                fontWeight: 600,
                fontSize: '16px',
                paddingRight: '1rem'
            }}
            >{formatValue(row.amount, true, row.currency, locale)}</Typography>
            </Box>
            <Box key={`mobile-card-last-row-${labelId}`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <Typography key={`mobile-card-date-${labelId}`}
            sx={{
                fontSize: '14px',
            }}
            >{formatTime(row.date, locale)}</Typography>
            <ChevronRight key={`mobile-card-icon-${labelId}`}
            sx={{
                marginLeft: 'auto',
                paddingRight: '0.5rem'}}/>
            </Box>
        </Box>
    );

    const getDateHeaderData = (key: string) => {
      const dateInMillis = Date.parse(key);
      const date = new Date(dateInMillis);
      let dayName = Intl.DateTimeFormat(locale, {weekday: 'long'}).format(dateInMillis);
      if(isToday(date)) {
        dayName = t('components.datePicker.today');
      } else if (isYesterday(date)) {
        dayName = t('components.datePicker.yesterday');
      }
      return {dayName,
        month: Intl.DateTimeFormat(locale, {month: 'long'}).format(dateInMillis),
        day: Intl.DateTimeFormat(locale, {day: 'numeric'}).format(dateInMillis),
        year: Intl.DateTimeFormat(locale, {year: 'numeric'}).format(dateInMillis)
      };
    }

    const getTransactionsMobileTable = useCallback(() => {
        if (!isError && !isLoading) {
          const transactionsSorted = stableSort<TransactionSummary>(
            totalRows,
            getComparator(order, 'date' as keyof TransactionSummary)
          );
          const transactionsClassifiedByDate: AssociativeArray = {};
          transactionsSorted.forEach((transaction) => {
            const {dayName, month, day, year} = getDateHeaderData(transaction.date);
            const dateKey: string = t('components.datePicker.longDateFormat', {
              dayName, month, day, year
            });
            if (!transactionsClassifiedByDate[dateKey]) {
              transactionsClassifiedByDate[dateKey] = [];
            }
            transactionsClassifiedByDate[dateKey].push(transaction);
          });
          return Object.keys(transactionsClassifiedByDate).map((key, dateIndex) =>
            <Box key={`${key}-outer`}>
              <Box key={`${key}-inner`} sx={{px: '16px', py: '6.5px', display: 'flex'}}>
                <Typography key={`${key}-title`} style={{ fontWeight: '700', fontSize: 16 }}>
                  {key}
                </Typography>
                {(!dateIndex &&  order === DESC) &&
                  <ArrowDownward sx={{marginLeft: 'auto'}} onClick={() => handleSortOrderChange(ASC)} />
                }
                {(!dateIndex &&  order === ASC) &&
                  <ArrowUpward sx={{marginLeft: 'auto'}} onClick={() => handleSortOrderChange(DESC)} />
                }
              </Box>
              <Box>
              {
                transactionsClassifiedByDate[key].map((row: TransactionSummary, index: number) => {
                  const labelId = `${row.transactionId}-${index}`;
                  const isLastRow = (index === totalRows.length - 1) && (dateIndex === Object.keys(transactionsClassifiedByDate).length - 1);
                  return (
                    <Box key={`${key}-${labelId}`} onClick={(event) => handleClick(event, row.transactionId, row.companyGuid)}>
                    {getTransactionMobileCard(labelId, row, isLastRow)}
                    </Box>
                  );
                })
              }
              </Box>
            </Box>
          );
        }
        return undefined;
      }, [isError, isLoading, totalRows, order, orderBy]);

    return (
      <>
        <Box sx={{ width: '100%'  }}>
            <EnhancedTableToolbar
              onFilterClick={toggleModal}
              isFilterOpen={filtersOpen}
              filtersButtonRef={filtersButtonRef}
              searchValue={searchInputValue}
              setSearchValue={handleChangeSearch}
              tableHasTransactions={totalRows?.length > 0}
            />
            {!isLoading && isError && (
              <FailedToLoad dense onClickRetry={() => defer(refresh)} />
            )}
            {totalRows.length === 0 && !isLoading && !isError && <NoTransactions dense />}
            {isLoading && !isError && <Loading dense />}

            {!isLoading && !isError && totalRows.length > 0 && (
            <Box key="mobileTransactionsTable">
              {getTransactionsMobileTable()}
              { totalRows?.length < count && !(totalRows.length % ROWS_PER_PAGE_MOBILE) &&
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    sx={{
                      margin: '2rem',
                      flex: 1,
                      borderTop: (theme) =>
                        `1px ${
                          colorMode === 'light' ? theme?.palette?.grey?.['12p'] : theme?.palette?.grey?.['23p']
                        } solid`
                      }}
                    onClick={() => getMoreTransactions(null, page + 1)}
                    >{t('screens.transactions.moreTransactions')}
                  </Button>
                </Box>
              }
            </Box>
            )}
            <Drawer
              ModalProps={{ keepMounted: false }}
              anchor="right"
              open={isDetailsOpen}
              onClose={() => defer(() => setDetailsOpen(false))}
              PaperProps={{ sx: { width: '450px', overflow: 'hidden' } }}
            >
              <Dialog open={isDetailsOpen} PaperProps={{sx: { borderRadius: 0 }}} fullScreen>
              <TransactionReportDetailsPanel
                transactionId={selected || 'ERR'}
                onClose={() => {
                  defer(() => setDetailsOpen(false));
                }}
                adminView={adminView}
                companyId={companyId}
                refetchTable={() => defer(refresh)}
              />
              </Dialog>
            </Drawer>
        </Box>
        <Dialog open={filtersOpen} PaperProps={{sx: { borderRadius: 0 }}} fullScreen>
          <TransactionReportFiltersPanel adminView={adminView} />
        </Dialog>
        <Modal
          sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}
          open={modalOpen}
          onClose={handleCloseModal}
          >
            <Box sx={{ backgroundColor: colorMode === 'light' ? 'grey.4p' : 'elevation.4dp',
              flexDirection: `column`, borderRadius : '8px', padding: '8px'}}>
              <Button onClick={openFilters} sx={{width: '100%'}}>
                <Box sx={{ marginRight: 'auto', display: 'flex', flexDirection: 'row' }}>
                  <FilterList sx={{marginRight: '8px'}}/>
                  {t('components.filters.filters')}
                </Box>
              </Button>
            {!isError && !isLoading && totalRows.length > 0  && (
                <Button
                  aria-controls={isExportMenuOpen ? 'long-menu' : undefined}
                  aria-expanded={isExportMenuOpen ? 'true' : undefined}
                  aria-haspopup="true"
                  sx={{width: '100%'}}
                  onClick={() => exportFilteredTransactions(false)}
                  variant="text"
                >
                   <Box sx={{ marginRight: 'auto', display: 'flex', flexDirection: 'row'}}>
                    <FileDownloadOutlinedIcon sx={{marginRight: '8px'}} />
                    {t('actions.export')}
                  </Box>
                </Button>
            )}
              </Box>
            </Modal>
            <ExportDialogWithApi
                filters={filters}
                reportType={exportReportType}
                start={exportReportStarted}
                onComplete={exportOnComplete}
                sortBy={orderBy}
                sortOrder={order}
                dateRangeConfig={dateRangeConfig}
                isAdmin={adminView}
                search={searchInputValue}
            />
      </>
    );
}

export default ReportListMobile;
