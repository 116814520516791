import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Drawer, Popper, Paper, Table, TableContainer, TableBody, Fade, TableCell, useTheme, debounce } from "@mui/material";
import { DataGridPro, GridColDef, GridColumnOrderChangeParams, GridColumnVisibilityModel, GridPaginationModel, GridRenderCellParams, GridRowParams, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro';
import { REPORT_TYPE_DETAILS, REPORT_TYPE_SUMMARY, TransactionsReportType } from '../../../api/transactions';
import { EnhancedTableToolbar } from '../../../components/EnhancedTableToolbar';
import IconMenu, { IMenuItem } from '../../../components/IconMenu';
import ToolButton from '../../../components/ToolButton';
import { SettingsContext } from '../../../context/SettingsContext';
import { TransactionFiltersContext } from '../../../context/TransactionFiltersContext';
import { UIContext } from '../../../context/UIContext';
import { UserContext } from '../../../context/UserContext';
import { formatDate } from '../../../helpers/dateUtils';
import { defer, formatValue, hasModule, hasVisibleColumns } from '../../../helpers/helpers';
import { buildDefaultTransactionColumnVisibilityModel, createVisibilityModel, getTransactionColumnsOrderPreferences, getTransactionSelectedColumnsPreferences, reorderVisibilityModel, saveTransactionColumnsOrderPreferences } from '../../../helpers/transactionHelpers';
import { TransactionSummary } from "../../../models/transactions";
import { ExportDialogWithApi } from './ExportDialogWithApi';
import FailedToLoad from './FailedToLoad';
import Loading from './Loading';
import NoTransactions from './NoTransactions';
import TransactionReportDetailsPanel from './TransactionReportDetailsPanel';
import TransactionReportFiltersPanel from './TransactionReportFiltersPanel';
import TransactionReportColumnsPanel from './TransactionReportColumnsPanel';
import { setupArrayAtPolyfill } from '../../../polyfills';

interface ReportTableProps {
    rows: TransactionSummary[];
    refetch: () => void;
    refresh: () => void;
    order: GridSortDirection;
    setOrder: (o: GridSortDirection) => void;
    orderBy: string;
    setOrderBy: (c: string) => void;
    search: string;
    page: number;
    setPage: (p: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (l: number) => void;
    setSearch: (s: string) => void;
    count: number;
    isLoading: boolean;
    isError: boolean;
    adminView?: boolean;
}

const ROW_SIZE = 52;

const FileDownloadIcon =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='rgb(89,89,89)' d='M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z' /%3E%3C/svg%3E";
const FileDownloadIconWhite =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='rgb(178,178,178)' d='M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z' /%3E%3C/svg%3E";

const ReportTable = (props: ReportTableProps) => {
    const {
        rows,
        refetch,
        refresh,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        search,
        setSearch,
        count,
        isLoading,
        isError,
        adminView,
    } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const { locale, userModules } = useContext(UserContext);
    const hasModuleInvoiceNumber = hasModule(userModules, "ModuleInvoiceNumbers");
    const [selected, setSelected] = useState<string>('');
    const [isDetailsOpen, setDetailsOpen] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [companyId, setCompanyId] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState(search);
    const [exportMenuAnchor, setExportMenuAnchor] = useState<HTMLElement | undefined>(undefined);
    const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);
    const [exportReportType, setExportReportType] = useState<TransactionsReportType>(REPORT_TYPE_SUMMARY);
    const [exportReportStarted, setExportReportStarted] = useState(false);
    const [columnsOpen, setColumnsOpen] = useState(false);
    const [rowNumberCurrentlyShowing, setRowNumberCurrentlyShowing] = useState(0);

    const { filters, filtersOpen, openFilters, closeFilters, chips, dateRangeConfig } = useContext(TransactionFiltersContext);
    const { settings } = useContext(SettingsContext);
    const dense = settings.densePadding === 'true';
    const { colorMode } = useContext(UIContext);

    // Support old browsers
    useEffect(setupArrayAtPolyfill, []);

    const defaultColumns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('screens.transactions.fields.date'),
            minWidth: 150,
            display: 'flex',
            flex: 1,
            valueGetter: (value, row: TransactionSummary): string => {
                const val = formatDate(row.date, locale)
                return val ;
            },
        },
        {
            field: 'amount',
            headerName: t('screens.transactions.fields.amount'),
            minWidth: 100,
            headerAlign: 'right',
            align: 'right',
            display: 'flex',
            flex: 1,
            valueGetter: (value, row: TransactionSummary): string => {
                const val = formatValue(row.amount, true, row.currency, locale)
                return val
            },
        },
        {
            field: 'tax',
            headerName: t('screens.transactions.fields.tax'),
            headerAlign: 'right',
            align: 'right',
            display: 'flex',
            minWidth: 100,
            flex: 1,
            valueGetter: (value, row: TransactionSummary) => {
                const val = formatValue(row.tax, true, row.currency, locale)
                return val
            },
        },
        {
            field: 'paymentMethod',
            headerName: t('screens.transactions.fields.paymentMethod'),
            headerAlign: 'left',
            align: 'left',
            display: 'flex',
            flex: 1,
            minWidth: 200,
            sortable: false,
            valueGetter: (value, row: TransactionSummary) => {
                const val = [
                    ...(row.loyaltyPointsDiscount ? ['Customer Points'] : []),
                    ...(row.paymentMethod ? [row.paymentMethod] : []),
                ].join(', ')
                return val
            },
        },
        {
            field: 'staff',
            headerName: t('screens.transactions.fields.staff'),
            headerAlign: 'left',
            align: 'left',
            display: 'flex',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'discountsSummary',
            headerName: t('screens.transactions.fields.discountAmount'),
            headerAlign: 'right',
            align: 'right',
            display: 'flex',
            minWidth: 100,
            flex: 1,
            sortable: false,
            valueGetter: (value, row: TransactionSummary) => {
                const val = formatValue(row.discountsSummary.totalDiscountAmount, true, row.currency, locale)
                return val
            },
        },
        {
            field: 'gratuity',
            headerName: t('screens.transactions.fields.gratuity'),
            headerAlign: 'right',
            align: 'right',
            display: 'flex',
            minWidth: 100,
            flex: 1,
            valueGetter: (value, row: TransactionSummary) => {
                const val = formatValue(row.gratuity || 0, true, row.currency, locale)
                return val
            },
        },
        {
            field: 'tableNumber',
            headerName: t('screens.transactions.fields.tableNumber'),
            headerAlign: 'right',
            align: 'right',
            display: 'flex',
            minWidth: 100,
            flex: 1,
            valueGetter: (value, row: TransactionSummary) => {
                const val = formatValue(row.tableNumber || 0, false, undefined, locale)
                return val
            },
        },
        {
            field: 'itemsSold',
            headerName: t('screens.transactions.fields.itemsSold'),
            headerAlign: 'left',
            display: 'flex',
            minWidth: 100,
            flex: 1,
            align: 'left',
        },
        {
            field: 'location',
            headerName: t('screens.transactions.fields.location'),
            headerAlign: 'left',
            display: 'flex',
            minWidth: 150,
            flex: 1,
            sortable: false,
            align: 'left',
        },
        {
            field: 'device',
            headerName: t('screens.transactions.fields.device'),
            headerAlign: 'left',
            display: 'flex',
            minWidth: 100,
            flex: 1,
            sortable: false,
            align: 'left',
        },
        {
            field: 'customer',
            headerName: t('screens.transactions.fields.customer'),
            headerAlign: 'left',
            display: 'flex',
            minWidth: 100,
            flex: 1,
            sortable: false,
            align: 'left',
        },
        {
            field: 'barcode',
            headerName: t('screens.transactions.fields.barcode'),
            headerAlign: 'left',
            display: 'flex',
            minWidth: 200,
            flex: 1,
            sortable: false,
            align: 'left',
        },
    ]

    useEffect(() => {
        if(rows.length < rowsPerPage) setRowNumberCurrentlyShowing(rows.length);
        else setRowNumberCurrentlyShowing(rowsPerPage);
    }, [rowsPerPage, page, rows])

    
    const invoiceNumberColumn: GridColDef = {
        field: 'invoiceNumbers',
        headerName: t('screens.transactions.fields.invoiceNumbers'),
        minWidth: 200,
        flex: 1,
        sortable: false,
        valueGetter: (value, row: TransactionSummary): string => {
            const val = row.invoiceNumbers? row.invoiceNumbers.join(', ') : 'test';
            return val
        },
        renderCell: (params: GridRenderCellParams<any, TransactionSummary>) => {
            const val = (
                <TableCell sx={{border: 'none', padding: 0}}>
                    {params.row?.invoiceNumbers?.map((invoiceNumber: string) => <div>{invoiceNumber}</div>)}
                </TableCell>
            )
            return val
        },
    }
    
    const companyIdColumn: GridColDef = {
        field: 'companyGuid',
        headerName: t('screens.transactions.fields.companyId'),
        headerAlign: 'left',
        display: 'flex',
        align: 'left',
        width: 250,
        sortable: false,
    }
    
    const openFeedback = useCallback((rowId: string) =>
        defer(() => {
            setFeedbackOpen(true);
            defer(() => setFeedbackOpen(false));
        }),
        [setFeedbackOpen]
    );
    
    const statusColumn: GridColDef = {
        field: 'activeFlagAdmin',
        type: 'boolean',
        headerName: t('actions.status'),
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, TransactionSummary>) => {
            const val = (
                <TableCell align="center" sx={{border: 'none', padding: 0}}>
                    {!!params.row.activeFlagAdmin && (
                        <FeedbackIcon
                            color="warning"
                            onClick={() => openFeedback(params.row.transactionId)}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                </TableCell>
            )
            return val
        },
    }

    const initialTableColumns: GridColDef[] = useMemo(() => {
        const columnsWithInvoiceNumbers = hasModuleInvoiceNumber ? [...defaultColumns, invoiceNumberColumn] : defaultColumns;

        return adminView ? [companyIdColumn, ...columnsWithInvoiceNumbers, statusColumn] : columnsWithInvoiceNumbers;
    }, [adminView, hasModuleInvoiceNumber]);

    const [tableColumns, setTableColumns] = useState<GridColDef[]>(() => {
        const columnOrder = getTransactionColumnsOrderPreferences(adminView);
        return columnOrder ? [...initialTableColumns].sort((col1, col2) => columnOrder.indexOf(col1.field) - columnOrder.indexOf(col2.field)) : initialTableColumns;
    });

    const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<GridColumnVisibilityModel>(
        {
            ...buildDefaultTransactionColumnVisibilityModel(createVisibilityModel(initialTableColumns), adminView),
            ...getTransactionSelectedColumnsPreferences(adminView)
        }
    );
    const handleColumnOrderChange = useCallback((params: GridColumnOrderChangeParams) => {
        const reorderedColumns = [...tableColumns];
        const column = reorderedColumns[params.oldIndex];
        reorderedColumns[params.oldIndex] = reorderedColumns[params.targetIndex];
        reorderedColumns[params.targetIndex] = column;
        setTableColumns(reorderedColumns);
        setColumnsVisibilityModel(reorderVisibilityModel(reorderedColumns, columnsVisibilityModel));
        saveTransactionColumnsOrderPreferences(reorderedColumns, adminView);
    }, [tableColumns, setTableColumns, columnsVisibilityModel, setColumnsVisibilityModel]);

    const handleRowClick = useCallback((params: GridRowParams) => {
            defer(() => {
                setSelected(params.row.transactionId);
                setCompanyId(params.row.companyGuid);
                setDetailsOpen(true);
            })
        }, [setSelected, setCompanyId, setDetailsOpen]
    );

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        if (sortModel?.length) {
            setOrder(sortModel[0].sort);
            setOrderBy(sortModel[0].field);
            defer(refetch);
        }
      }, [order, setOrder, orderBy, setOrderBy, refetch]
    );

    const handlePaginationModelChange = useCallback((paginationModel: GridPaginationModel) => {
        setPage(paginationModel.page);
        setRowsPerPage(paginationModel.pageSize);
        defer(refetch)
      }, [page, setPage, rowsPerPage, setRowsPerPage, refetch]
    );

    const filtersButtonRef = useRef(null);
    const toggleFilters = useCallback(() =>
        defer(() => {
            setColumnsOpen(false);
            if (filtersOpen) {
                closeFilters(true);
            } else {
                openFilters();
            }
        }), [filtersOpen, closeFilters, openFilters]
    );
    const toolbarChips = useMemo(() =>
        chips.filter((chip) => !chip.adminView || adminView)
             .map((chip) => ({
                ...chip,
                onClick: chip.onClick ? chip.onClick : toggleFilters,
            })
        ), [chips, adminView, toggleFilters]
    );

    const toggleColumns = useCallback(() => {
            closeFilters(true);
            setColumnsOpen(!columnsOpen);
        }, [columnsOpen, closeFilters, setColumnsOpen]
    );

    const debouncedSearch = useMemo(() => debounce(setSearch, 500), [setSearch]);
    const handleChangeSearch = useCallback((newSearchValue: string) => {
        setSearchInputValue(newSearchValue);
        debouncedSearch(newSearchValue);
      }, [debouncedSearch, setSearchInputValue]
    );
    
    const openExportMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            setExportMenuAnchor(event.currentTarget);
            setIsExportMenuOpen((prevOpen) => !prevOpen);
        }, [setExportMenuAnchor, setIsExportMenuOpen]
    );
    
    const exportOnComplete = useCallback(() => {
            setExportReportStarted(false);
        }, [setExportReportStarted]
    );
    
    const exportFilteredTransactions = useCallback((withDetails = false) => {
          if (exportReportStarted) {
            return;
          }
          setExportReportType(withDetails ? REPORT_TYPE_DETAILS : REPORT_TYPE_SUMMARY);
          setExportReportStarted(true);
        },
        [exportReportStarted, setExportReportStarted, setExportReportType]
    );

    const exportMenuItems = useMemo(() =>
          [{
              dataQaId: "exportMenuTransactionsCSV",
              title: t('export.filteredTransactions'),
              onClick: () => {
                exportFilteredTransactions(false);
              },
              icon: (
                <img
                  src={colorMode === 'light' ? FileDownloadIcon : FileDownloadIconWhite}
                  style={{ width: '22px', height: '22px' }}
                  alt={t('export.filteredTransactions')}
                />
              ),
              iconPosition: 'right',
              rightText: '.csv',
            },
            {
              dataQaId: "exportMenuTransactionsWithDetailsCSV",
              title: t('export.filteredTransactionsWithDetails'),
              onClick: () => {
                exportFilteredTransactions(true);
              },
              icon: (
                <img
                  src={colorMode === 'light' ? FileDownloadIcon : FileDownloadIconWhite}
                  style={{ width: '22px', height: '22px' }}
                  alt={t('export.filteredTransactions')}
                />
              ),
              iconPosition: 'right',
              rightText: '.csv',
              hidden: true,
            },
          ] as IMenuItem[],
        [t, exportFilteredTransactions, colorMode]
    );

    return (
        <Box id="box-container" 
             sx={{ 
                width: '100%', 
                flexGrow: 1,
                height: (!rows?.length || isError || isLoading) ? '100%' : 'unset'
            }}
        >
            <Paper 
                sx={{ 
                    width: '100%', 
                    height: '100%',
                    display: 'flex',
                    flexFlow: 'column'
                }} 
                variant="outlined">
                    <EnhancedTableToolbar
                        onFilterClick={toggleFilters}
                        chips={toolbarChips}
                        isFilterOpen={filtersOpen}
                        filtersButtonRef={filtersButtonRef}
                        searchValue={searchInputValue}
                        setSearchValue={handleChangeSearch}
                        extraButtons={
                        !isError && !isLoading && rows.length > 0 ? (
                            <Box sx={{ marginRight: { xs: 0, md: '1.5em' } }}>
                            <ToolButton
                                iconElement={<FileDownloadOutlinedIcon />}
                                tooltip={t('actions.export')}
                                onClick={(ev) => openExportMenu(ev)}
                                text={t('actions.export')}
                                active={isExportMenuOpen}
                                id="exportButton"
                                buttonStyle={{ marginRight: '16px' }}
                                dataQaId="tableExportButton"
                            />
                            </Box>
                        ) : undefined
                        }
                        onColumnClick={toggleColumns}
                        isColumnOpen={columnsOpen}
                        tableHasTransactions={!isError && !isLoading && rows.length > 0}
                    />
                    <ExportDialogWithApi
                        filters={filters}
                        reportType={exportReportType}
                        start={exportReportStarted}
                        onComplete={exportOnComplete}
                        sortBy={orderBy}
                        sortOrder={order}
                        dateRangeConfig={dateRangeConfig}
                        isAdmin={adminView}
                        search={searchInputValue}
                    />
                    <IconMenu
                        items={exportMenuItems}
                        open={isExportMenuOpen}
                        onClose={() => {
                        setIsExportMenuOpen(false);
                        }}
                        ariaLabelledBy="exportButton"
                        anchorEl={exportMenuAnchor}
                        menuProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        transformOrigin: { vertical: 'top', horizontal: 'center' },
                        sx: { marginTop: '16px' },
                        }}
                    />
                    <Box sx={{ flex: 1, 
                                position: 'relative', width: '100%', height: '100%', minHeight: `${ROW_SIZE * (rowNumberCurrentlyShowing + 2)}px`}} >
                                    <Box sx={{ 
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        inset: 0,
                                        }}>
                            {isLoading && <Loading dense={dense} />}
                            {!isLoading && isError && (
                                <FailedToLoad dense={dense} onClickRetry={() => defer(refresh)} />
                            )}
                            {(rows.length === 0 || !hasVisibleColumns(columnsVisibilityModel)) && !isLoading && !isError && <NoTransactions dense={dense} />}
                            {!isLoading && !isError && rows.length > 0 && hasVisibleColumns(columnsVisibilityModel) && (
                                <DataGridPro
                                    autoHeight
                                    columns={tableColumns}
                                    columnVisibilityModel={columnsVisibilityModel}
                                    columnHeaderHeight={55}
                                    onColumnOrderChange={handleColumnOrderChange}
                                    disableColumnMenu
                                    rows={rows}
                                    getRowId={(row: TransactionSummary) => row.transactionId}
                                    loading={isLoading}
                                    disableMultipleRowSelection
                                    onRowClick={handleRowClick}
                                    sortingMode='server'
                                    onSortModelChange={handleSortModelChange}
                                    pagination
                                    paginationMode='server'
                                    pageSizeOptions={[10,25,50,100]}
                                    onPaginationModelChange={handlePaginationModelChange}
                                    rowCount={count}
                                    initialState={{
                                        columns: { columnVisibilityModel: columnsVisibilityModel },
                                        pagination: { paginationModel: { page, pageSize: rowsPerPage } },
                                        sorting: { sortModel: [{ field: orderBy, sort: order}] } ,
                                    }}
                                    localeText={{
                                        MuiTablePagination: {
                                            labelRowsPerPage: t('components.filters.rowsPerPage')
                                        },
                                        footerRowSelected: () => ''
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                                            outline: 'none',
                                        },
                                        
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 600,
                                        },
                                        '& .MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller, .MuiDataGrid-filler': {
                                            backgroundColor: 'background.paper'
                                        },
                                        '& ::-webkit-scrollbar': {
                                            width: '6px',
                                            height: '6px'
                                        },
                                        '& ::-webkit-scrollbar-thumb': {
                                            borderRadius: '10px',
                                            backgroundColor: theme?.palette?.grey?.['50p']
                                        },
                                        '& .MuiDataGrid-cell': {
                                            minHeight: dense ? '26px': '52px',
                                            paddingTop: dense ? '8px' : '16px',
                                            paddingBottom: dense ? '8px' : '16px'
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                     <Popper open={isDetailsOpen} placement="bottom-end">
                        <Fade mountOnEnter unmountOnExit in={isDetailsOpen}>
                            <Paper
                                sx={{
                                    width: '450px',
                                    height: 'calc(100vh - 64px)',
                                    marginTop: { xs: '5px', sm: '9px', md: '7px' },
                                    position: 'fixed',
                                    right: '16px',
                                    bottom: 0,
                                    overflow: 'hidden',
                                    borderRadius: '0px',
                                    boxShadow:
                                    '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
                                    clipPath: 'inset(0px 0px 0px -74px)',
                                    backgroundColor: 'background.paper',
                                    backgroundImage: 'none',
                                }}
                            >
                                <TransactionReportDetailsPanel
                                    transactionId={selected || 'ERR'}
                                    onClose={() => {
                                        defer(() => setDetailsOpen(false));
                                    }}
                                    adminView={adminView}
                                    startOnFeedback={feedbackOpen}
                                    companyId={companyId}
                                    refetchTable={() => defer(refresh)}
                                />
                            </Paper>
                        </Fade>
                    </Popper>
                    <Popper open={filtersOpen && !columnsOpen} anchorEl={filtersButtonRef.current} placement="bottom-end">
                        <Fade mountOnEnter unmountOnExit in={filtersOpen}>
                            <Paper
                                sx={{
                                    width: '450px',
                                    height: rows?.length ? `${ROW_SIZE * (rowNumberCurrentlyShowing + 2)}px` : 'auto',
                                    minHeight: `calc(100vh - 320px)`,
                                    marginTop: { xs: '5px', sm: '9px', md: '7px' },
                                    position: 'fixed',
                                    right: '-16px',
                                    top: 0,
                                    overflow: 'auto',
                                    borderRadius: '0px',
                                    boxShadow:
                                    '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
                                    clipPath: 'inset(0px 0px 0px -74px)',
                                    backgroundColor: 'background.paper',
                                    backgroundImage: 'none',
                                }}
                            >
                                <TransactionReportFiltersPanel adminView={adminView} />
                            </Paper>
                        </Fade>
                    </Popper>
                    <Popper open={columnsOpen && !filtersOpen} anchorEl={filtersButtonRef.current} placement="bottom-end">
                        <Fade mountOnEnter unmountOnExit in={columnsOpen}>
                            <Paper
                                sx={{
                                    width: '450px',
                                    height: `${ROW_SIZE * (rowNumberCurrentlyShowing + 2)}px`,
                                    minHeight: `calc(100vh - 320px)`,
                                    marginTop: { xs: '5px', sm: '9px', md: '7px' },
                                    position: 'fixed',
                                    right: '-16px',
                                    top: 0,
                                    borderRadius: '0px',
                                    boxShadow:
                                    '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
                                    clipPath: 'inset(0px 0px 0px -74px)',
                                    backgroundColor: 'background.paper',
                                    backgroundImage: 'none',
                                }}
                            >
                                <TransactionReportColumnsPanel
                                    initialTableColumns={initialTableColumns}
                                    tableColumns={tableColumns}
                                    setTableColumns={setTableColumns}
                                    columnsVisibilityModel={columnsVisibilityModel}
                                    setColumnsVisibilityModel={setColumnsVisibilityModel}
                                    closeColumns={() => toggleColumns()}
                                    adminview={adminView}
                                />
                            </Paper>
                        </Fade>
                    </Popper>
            </Paper>
        </Box>
    );
};

export default ReportTable;
