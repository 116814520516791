export interface TroncLocation {
    id: string;
    name: string;
    locationAreaId?: string;
}

export interface TroncLocationArea {
    id: string;
    name: string;
    parentId?: string;
}

export interface TroncReportFilters {
    period: string;
    periodFrom?: string;
    periodTo?: string;
    location?: TroncLocation;
    companyGuid?: string;
}

export interface TroncStaff {
    id: string;
    name: string;
    mainLocationId: string;
}

export interface TroncTenderType {
    id: string;
    name: string;
    description?: string;
}

export interface TroncSummary {
    totalHours: number;
    totalGratuity: number;
    totalServiceCharge: number;
    totalTronc: number;
}

export interface TroncRow {
    requestStartTime: string;
    requestEndTime: string;
    staffName: string;
    staffId: string;
    locationName: string;
    locationId: string;
    hours: number;
    gratuity: number;
    serviceCharge: number;
    totalTronc: number;
    noOfShiftRecords: number;
}

const hashCode = (str: string) => {
    let hash = 0;
    let i;
    let chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i += 1) {
      chr = str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash) + chr;
    }
    return hash;
  }
  

export const troncHash = (tronc: TroncRow) => hashCode(JSON.stringify(tronc));

export interface TroncLocationFilters {
    locations: TroncLocation[];
    locationAreas: TroncLocationArea[];
}
