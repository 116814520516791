import React, { useCallback, useContext, useMemo } from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertMessage, MainHeader } from '@eposnow/ui-core';
import {
  comboStyle,
  desktopButtonStyle,
  desktopSecondaryButtonStyle,
  mobileButtonStyle,
  mobileSecondaryButtonStyle,
} from '../../constants';
import { SettingsContext } from '../../context/SettingsContext';

import { UIContext } from '../../context/UIContext';
import { UserContext } from '../../context/UserContext';
import { DayOfWeek } from '../../helpers/dateUtils';
import useSavedField from '../../helpers/useSavedField';
import { ReactComponent as WhiteScheduleIcon } from '../../img/schedule-white.svg';
import { ReactComponent as ScheduleIcon } from '../../img/schedule.svg';
import { ReactComponent as WhiteTableIcon } from '../../img/table-white.svg';
import { ReactComponent as TableIcon } from '../../img/table.svg';

const subheaderStyle = { color: 'text.secondary', fontSize: '16px', marginTop: '4px' };

const StyledToggleSwitchLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontWeight: 400,
    fontSize: '16px',
  },
}));

const Settings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, locale } = useContext(UserContext);
  const { colorMode } = useContext(UIContext);
  const { settings, setSetting } = useContext(SettingsContext);

  const linkNavigation = useCallback(
    (path: string | undefined) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const defaultds = useMemo(() => {
    const d = new Date();
    d.setHours(parseInt(settings.daysStart, 10));
    d.setMinutes(0);
    d.setSeconds(0);
    return d;
  }, [settings]);

  const {
    val: daysStart,
    setVal: setDaysStart,
    changed: daysStartChanged,
    apply: applyDaysStart,
    reset: resetDaysStart,
  } = useSavedField(defaultds);
  const {
    val: weeksStart,
    setVal: setWeeksStart,
    changed: weeksStartChanged,
    apply: applyWeeksStart,
    reset: resetWeeksStart,
  } = useSavedField(settings.weeksStart);
  
  const {
    val: densePadding,
    setVal: setDensePadding,
    changed: densePaddingChanged,
    apply: applyDensePadding,
    reset: resetDensePadding,
  } = useSavedField(settings.densePadding);
  const {
    val: rowsPerPage,
    setVal: setRowsPerPage,
    changed: rowsPerPageChanged,
    apply: applyRowsPerPage,
    reset: resetRowsPerPage,
  } = useSavedField(settings.rowsPerPage);

  const unsavedChanges =
    daysStartChanged ||
    weeksStartChanged ||
    densePaddingChanged ||
    rowsPerPageChanged;
  const { isMobile } = useContext(UIContext);
  const buttonStyle = isMobile ? mobileButtonStyle : desktopButtonStyle;
  const secondaryButtonStyle = isMobile ? mobileSecondaryButtonStyle : desktopSecondaryButtonStyle;

  const resetSettings = useCallback(() => {
    resetDaysStart();
    resetWeeksStart();
    resetDensePadding();
    resetRowsPerPage();
  }, [resetDaysStart, resetWeeksStart, resetDensePadding, resetRowsPerPage]);

  const saveSettings = useCallback(() => {
    setSetting('daysStart', daysStart.getHours().toString());
    applyDaysStart();
    setSetting('weeksStart', weeksStart);
    applyWeeksStart();
    setSetting('densePadding', densePadding);
    applyDensePadding();
    setSetting('rowsPerPage', rowsPerPage);
    applyRowsPerPage();
  }, [
    daysStart,
    weeksStart,
    setSetting,
    applyDaysStart,
    applyWeeksStart,
    densePadding,
    applyDensePadding,
    rowsPerPage,
    applyRowsPerPage,
  ]);

  return (
    <>
      <Box
        sx={{
          marginLeft: 0,
          marginBottom: { xs: 0, sm: 1 },
          paddingX: { xs: 2, sm: 0 },
          height: '4.75rem',
        }}
      >
        <MainHeader
          title={t('screens.settings.title', { name: `${user?.fullName}` })}
          nav={[
            {
              text: t('nav.reporting'),
              link: '/',
              icon: <ShowChartOutlinedIcon sx={{ mr: 1 }} fontSize="inherit" />,
            },
            {
              text: t('nav.settings'),
              link: '/settings',
              icon: <SettingsOutlinedIcon sx={{ mr: 1 }} fontSize="inherit" />,
            },
          ]}
          theme={theme}
          linkNavigationFunction={linkNavigation}
        />
      </Box>
      <Card variant="outlined" sx={{overflow: 'visible'}}>
        <Box
          sx={{
            padding: '16px 24px 16px 24px',
            borderBottom: `1px ${
                colorMode === 'light'
                  ? theme?.palette?.grey?.['12p']
                  : theme?.palette?.grey?.['23p']
              } solid`,
              display: 'flex'
          }}
        >
          <Grid container>
            <Grid item xs>
              <Typography variant="h3" sx={{ marginTop: '8px' }}>
                {t('screens.settings.dataTablesHeader')}
              </Typography>
              <Typography sx={subheaderStyle}>
                {t('screens.settings.dataTablesDescription')}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {colorMode === 'light' ? <TableIcon /> : <WhiteTableIcon />}
            </Grid>
          </Grid>
        </Box>
        <Grid container sx={{ padding: '24px' }}>
          <Grid item xs>
            <Card
              variant="outlined"
              sx={{ padding: '24px', marginRight: '12px', minHeight: '238px' }}
            >
              <Grid container>
                <Grid item xs>
                  <Typography variant="h3">{t('screens.settings.rowsHeader')}</Typography>
                  <Typography sx={subheaderStyle}>
                    {t('screens.settings.rowsDescription')}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <TableRowsOutlinedIcon />
                </Grid>
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{ marginTop: '36px', marginLeft: '16px', marginBottom: '16px' }}
              >
                <TextField
                  select
                  inputProps={{ 'data-testid': 'rowsPerPageSelect', 'data-qa-id': 'dataTablesRowsPerPageOption'  }}
                  value={rowsPerPage}
                  label={t('screens.settings.rowsPerPage')}
                  id="rowsPerPage"
                  sx={{
                    ...comboStyle,
                    width: '260px',
                  }}
                  onChange={(ev) => {
                    setRowsPerPage(ev.target.value);
                  }}
                >
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                </TextField>
              </Grid>
              <Grid
                container
                onClick={() =>
                  setDensePadding((prevDense) => (prevDense === 'true' ? 'false' : 'true'))
                }
                sx={{ cursor: 'pointer' }}
              >
                <Grid item xs>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      marginLeft: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {t('screens.settings.densePadding')}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <StyledToggleSwitchLabel
                    labelPlacement="start"
                    sx={{
                      marginLeft: '16px',
                      marginTop: '-6px',
                      width: '100%',
                      'white-space': 'nowrap',
                    }}
                    onClick={() =>
                      setDensePadding((prevDense) => (prevDense === 'true' ? 'false' : 'true'))
                    }
                    control={
                      <Switch
                        sx={{ marginRight: '4px', marginLeft: '8px' }}
                        onClick={() =>
                          setDensePadding((prevDense) => (prevDense === 'true' ? 'false' : 'true'))
                        }
                        checked={densePadding === 'true'}
                        data-qa-id="dataTablesDensePaddingToggle"
                      />
                    }
                    label={densePadding === 'true' ? t('actions.enabled') : t('actions.disabled')}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* Hidden item for alignment */}
          <Grid item xs>
            <Card
              variant="outlined"
              sx={{ padding: '24px', marginLeft: '12px', minHeight: '238px', display: 'none'}}
             />
          </Grid>
        </Grid>
      </Card>
      <Card variant="outlined" sx={{ padding: '24px', marginTop: '24px', overflow: 'visible'}}>
        <Grid container>
          <Grid item xs>
            <Typography variant="h3">{t('screens.settings.businessScheduleHeader')}</Typography>
            <Typography sx={subheaderStyle}>
              {t('screens.settings.businessScheduleDescription')}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            {colorMode === 'light' ? <ScheduleIcon /> : <WhiteScheduleIcon />}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="left"
          spacing={2}
          sx={{ marginTop: '4px' }}
        >
          <Grid item xs="auto">
            <TextField
              select
              inputProps={{ 'data-testid': 'weekStartsSelect', 'data-qa-id': "businessScheduleWeekStartsAt" }}
              value={weeksStart}
              label={t('screens.settings.weekStartsOn')}
              id="weeksStartOn"
              sx={{ ...comboStyle, width: '260px' }}
              onChange={(ev) => {
                setWeeksStart(ev.target.value as unknown as DayOfWeek);
              }}
            >
              <MenuItem value="monday">{t('weekdays.monday')}</MenuItem>
              <MenuItem value="tuesday">{t('weekdays.tuesday')}</MenuItem>
              <MenuItem value="wednesday">{t('weekdays.wednesday')}</MenuItem>
              <MenuItem value="thursday">{t('weekdays.thursday')}</MenuItem>
              <MenuItem value="friday">{t('weekdays.friday')}</MenuItem>
              <MenuItem value="saturday">{t('weekdays.saturday')}</MenuItem>
              <MenuItem value="sunday">{t('weekdays.sunday')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs="auto">
            <TimePicker
              ampm
              minutesStep={60}
              value={daysStart}
              views={['hours']}
              onChange={(val) => {
                setDaysStart(val || daysStart);
              }}
              format="hh a"
              label={t('screens.settings.dayStartsAt')}
              data-testId="daysStartAtPicker"
              slotProps={{
                textField: {
                  style: {
                    ...comboStyle,
                    width: '260px',
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccessTimeOutlinedIcon />
                      </InputAdornment>
                    ),
                  },
                  inputProps: {'data-qa-id': "businessScheduleDayStartsAt"},
                },
              }}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing="16px" sx={{ marginTop: '8px' }}>
        <Grid item xs data-qa-id="unsavedChangesAlert">
          {unsavedChanges && (
            <AlertMessage type="warning" locale={locale} theme={theme}>
              {t('errors.unsavedChanges')}
            </AlertMessage>
          )}
        </Grid>
        <Grid item xs="auto" sx={{ paddingLeft: 0 }}>
          {unsavedChanges && (
            <Button
              variant="text"
              onClick={resetSettings}
              sx={{
                ...secondaryButtonStyle,
                backgroundColor: 'inherit',
                paddingLeft: '24px',
                paddingRight: '24px',
                marginLeft: '4px',
                marginRight: '16px',
              }}
              data-qa-id="discardChangesButton"
            >
              {t('actions.discard')}
            </Button>
          )}
          {unsavedChanges && (
            <Button
              variant="contained"
              onClick={saveSettings}
              sx={{ ...buttonStyle }}
              data-qa-id="saveChangesButton"
            >
              {t('actions.save')}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
