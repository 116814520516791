import {EN_GB} from "./helpers";

function pad(n: number) {
    return n.toString(10).padStart(2, "0");
}

export function toISOStringSeconds(date: Date, localtime = false): string {
    if (localtime) {
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
    }
    // remove milliseconds as its not supported by API
    return date.toISOString().replace(/\.\d{3}/gi, "");
}

export type DayOfWeek = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
export const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;
export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export interface DateRangeConfig {
    // day of the week that weeks begin by
    weeksStartOn: DayOfWeek;
    // time of the day the days start at
    daysStartAt: number;
    // yield local time instead of UTC time
    useLocalTime?: boolean;
}


export function startOfDay(date: Date, config?: DateRangeConfig): Date {
    const dat = new Date(date);
    if (config && dat.getHours() < config.daysStartAt && config.daysStartAt < 12) {
        dat.setDate(dat.getDate() - 1);
    }
    dat.setHours(config?.daysStartAt || 0);
    dat.setMinutes(0);
    dat.setSeconds(0);
    dat.setMilliseconds(0);
    return dat;
}

export function endOfDay(date: Date, config?: DateRangeConfig): Date {
    const dat = new Date(date);
    const daysStartAt = config ? config?.daysStartAt : 5;
    if (config && dat.getHours() < daysStartAt && config.daysStartAt < 12) {
        dat.setDate(dat.getDate() - 1);
    }
    dat.setHours((daysStartAt || 0) + 24);
    dat.setMinutes(0);
    dat.setSeconds(0);
    dat.setMilliseconds(0);
    return dat;
}

export function getTradingDate(date: Date, config?: DateRangeConfig): Date {
    const day = config?.daysStartAt && date.getHours() < config.daysStartAt ? date.getDate() - 1 : date.getDate();
    const dat = new Date(date.getFullYear(), date.getMonth(), day);
    dat.setHours(config?.daysStartAt || 0, 0, 0, 0);
    return dat;
}

function weekOffset(config?: DateRangeConfig): number {
    return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].indexOf(config?.weeksStartOn || "monday");
}

export function dateRange(period: string, customFrom?: string, customTo?: string, forDate?: string, config?: DateRangeConfig): { startDate: string, endDate: string } {
    let startDate: Date = getTradingDate(forDate ? new Date(forDate) : new Date(), config);
    let endDate: Date = getTradingDate(forDate ? new Date(forDate) : new Date(), config);
    if (period === "today") {
        startDate = startOfDay(startDate, config);
        endDate = endOfDay(endDate, config);
    }
    if (period === "yesterday") {
        startDate = startOfDay(startDate, config);
        startDate.setDate(startDate.getDate() - 1);
        endDate = endOfDay(endDate, config);
        endDate.setDate(endDate.getDate() - 1);
    }
    if (period === "thisWeek") {
        const isOver = startDate.getDay() < weekOffset(config);
        startDate.setDate(startDate.getDate() - startDate.getDay() + weekOffset(config) - (isOver ? 7 : 0));
        endDate.setDate(endDate.getDate() - endDate.getDay() + 7 + weekOffset(config) - (isOver ? 7 : 0));
    }
    if (period === "lastWeek") {
        const isOver = startDate.getDay() < weekOffset(config);
        startDate.setDate(startDate.getDate() - startDate.getDay() - 7 + weekOffset(config) - (isOver ? 7 : 0));
        endDate.setDate(endDate.getDate() - endDate.getDay() + weekOffset(config) - (isOver ? 7 : 0));
    }
    if (period === "thisMonth") {
        startDate.setDate(1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1, config?.daysStartAt);
    }
    if (period === "lastMonth") {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1, config?.daysStartAt);
        endDate.setDate(1);
    }
    if (period === "custom") {
        if (!customFrom || !customTo) {
            throw new Error("a custom date range was defined but no custom dates were supplied");
        }
        startDate = new Date(customFrom);
        endDate = new Date(customTo);
    }
    return {
        startDate: toISOStringSeconds(startDate, config?.useLocalTime),
        endDate: toISOStringSeconds(endDate, config?.useLocalTime)
    };
}

export function getLabelForPeriod(period: string, customFrom?: string, customTo?: string, translate?: (key: string) => string, locale = EN_GB): string {
    const maybeT = (key: string, fallback: string): string => {
        if (translate) {
            return translate(key);
        }
        return fallback;
    }
    switch (period) {
        case "today":
            return maybeT("components.datePicker.today", "Today");
        case "yesterday":
            return maybeT("components.datePicker.yesterday", "Yesterday");
        case "thisWeek":
            return maybeT("components.datePicker.thisWeek", "This Week");
        case "lastWeek":
            return maybeT("components.datePicker.lastWeek", "Last Week");
        case "thisMonth":
            return maybeT("components.datePicker.thisMonth", "This Month");
        case "lastMonth":
            return maybeT("components.datePicker.lastMonth", "Last Month");
        case "custom":
            if (customFrom && customTo) {
                const from = new Date(customFrom);
                const to = new Date(customTo);
                return `${maybeT("components.datePicker.from", "From")} ${from.toLocaleDateString(locale)} ${maybeT("components.datePicker.to", "to")} ${to.toLocaleDateString(locale)}`;
            }
            return "Custom";
        default:
            if (customFrom && customTo) {
                const from = new Date(customFrom);
                const to = new Date(customTo);
                return `${maybeT("components.datePicker.from", "From")} ${from.toLocaleDateString(locale)} ${maybeT("components.datePicker.to", "to")} ${to.toLocaleDateString(locale)}`;
            }
            return "Unknown";
    }

}

const areSameDay = (first: Date, second: Date) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();

export const isToday = (date: Date) => areSameDay(date, new Date());

export const isYesterday = (date: Date) =>  areSameDay(date, new Date(Date.now() - MILLIS_PER_DAY));

export function formatDate(inputDate: string, locale = EN_GB) {
    return Intl.DateTimeFormat(locale, {dateStyle: 'short', timeStyle: 'short'}).format(
        Date.parse(inputDate)
    );
}

export function TInputDateToString(date: Date | string | null | undefined, config?: DateRangeConfig) {
    return date ? toISOStringSeconds(new Date(date), config?.useLocalTime || true) : "";
}

export const formatTime = (date: string, locale = EN_GB) => {
    const parsedDate = Date.parse(date);
    return new Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit' }).format(parsedDate);
}
